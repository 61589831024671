import { Box, Button, Input, Spacer, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import React, { useState } from "react";
import PaymentMenu from "./atoms/PaymentMenu";

const PaymentReportFilter: React.FC<{ onKeywordChange: (keyword: string, startDate: string, endDate: string) => void }> = ({ onKeywordChange }) => {
    const [dateRange, setDateRange] = useState<string | null>(null);
    const [keyword, setKeyword] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [typingTimeout, setTypingTimeout] = useState<number | undefined>(undefined);
  
    const handleDateRangeChange = (start: string, end: string) => {
      const range = `${start} - ${end}`;
      setDateRange(range);
      setStartDate(start);
      setEndDate(end);
    };
  
    const handleKeywordChange = (value: string) => {
      setKeyword(value);
      setIsConfirmed(false);
  
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
  
      const delay = 1000;
      const newTypingTimeout = window.setTimeout(() => {
        onKeywordChange(value, startDate, endDate);
      }, delay);
  
      setTypingTimeout(newTypingTimeout);
    };
  
    const handleConfirm = () => {
      setIsConfirmed(true);
      onKeywordChange(keyword, startDate, endDate);
    };
  
    const handleReset = () => {
      setDateRange(null);
      setKeyword('');
      setStartDate('');
      setEndDate('');
      setIsConfirmed(false);
      onKeywordChange('', '', '');
    };
  
  return (
    <Box display='flex' flexDirection='row' backgroundColor='white' borderRadius='5px' padding='15px' justifyContent='flex-start'>
      <PaymentMenu onDateRangeChange={handleDateRangeChange} onConfirm={handleConfirm} onReset={handleReset} />
      {isConfirmed && (
        <Tag 
          variant='subtle' 
          colorScheme='orange' 
          borderRadius='full' 
          ml='2'
        >
          {dateRange && <TagLabel>{dateRange}</TagLabel>}
          {keyword && <TagLabel>{keyword}</TagLabel>}
          <TagCloseButton onClick={handleReset} />
        </Tag>
      )}
      <Spacer />
      <Input
        variant='outline'
        placeholder='Enter your keyword'
        style={{ width: '450px' }}
        value={keyword}
        onChange={(e) => handleKeywordChange(e.target.value)}
      />
    </Box>
  );
};

export default PaymentReportFilter;

  