import { Box, Button, FormControl, FormLabel, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";

interface PaymentMenuProps {
    onDateRangeChange: (start: string, end: string) => void;
    onConfirm: () => void;
    onReset: () => void;
  }
  
  const PaymentMenu: React.FC<PaymentMenuProps> = ({ onDateRangeChange, onConfirm, onReset }) => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
  
    const handleDateRangeChange = () => {
      onDateRangeChange(startDate, endDate);
    };
  
    const handleConfirm = () => {
      onConfirm();
    };
  
    const handleReset = () => {
      setStartDate('');
      setEndDate('');
      onReset();
    };
  
    return (
      <Menu>
        <MenuButton as={Button} colorScheme='orange'>
          Filter
        </MenuButton>
        <MenuList
          style={{
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '5px',
            padding: '15px',
          }}
        >
          <Box margin='15px'>
            <FormControl>
              <FormLabel>Processed Date</FormLabel>
              <Flex>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                  border='none'
                  marginRight="8px"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  onBlur={handleDateRangeChange}
                />
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                  border='none'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  onBlur={handleDateRangeChange}
                />
              </Flex>
            </FormControl>
          </Box>
          <Button colorScheme="green" onClick={handleConfirm} marginRight='10px'>
            Confirm
          </Button>
          <Button colorScheme="red" onClick={handleReset}>
            Reset
          </Button>
        </MenuList>
      </Menu>
    );
  };
  
  export default PaymentMenu;
  