import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
  isLoggedIn: boolean;
}

function ProtectedRoute({ children, isLoggedIn }: ProtectedRouteProps) {
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;







