import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice'; // Import your authentication reducer

const store = configureStore({
  reducer: {
    auth: authReducer,
    
  },
});

// Export the RootState type
export type RootState = ReturnType<typeof store.getState>;

export default store;
