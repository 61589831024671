import React from "react";
import ReactApexChart from "react-apexcharts";

type ChartType =
  | "bar"
  | "line"
  | "area"
  | "histogram"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "treemap"
  | "boxPlot"
  | "candlestick"
  | "radar"
  | "polarArea"
  | "rangeBar";

interface ChartProps {
  type: ChartType;
  categories: string[] | null;
  values: number[];
}

const Chart = (props: ChartProps) => {
  const chartOptions = {
    noData: {
      text: "Loading",
    },
    fill: {
      colors: ["#ffa500", "#ff5800"],
    },
    chart: {
      id: "basic",
    },
    xaxis: {
      categories: props.categories,
    },
    yaxis: {
      tickAmount: 4,
      min: Math.min.apply(Math, props.values),
      max: Math.max.apply(Math, props.values),
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartSeries = [
    {
      name: "New Sign up count",
      data: props.values,
    },
  ];

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartSeries}
      type={props.type as ChartType}
      height={350}
      margin="2%"
    />
  );
};

export default Chart;
