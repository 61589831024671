import React, { useEffect } from "react";
import Header from "../components/Header";
import NewSignUpsTable from "../components/tables/NewSignupsTable";
import ReportFilters from "../components/ReportFilters";
import { Card, CardBody, Box } from "@chakra-ui/react";
import Chart from "../charts/Chart";
import { newSignupAnalytics } from "../apis/ApiService";
import { useState } from "react";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import dayjs from 'dayjs';
import { errorHandler } from "../services/ErrorHandler";
import { useNavigate } from "react-router-dom";

interface DataItem {
  total_count: number;
  date: string;
}

interface AnalyticsData {
  categories: string[];
  values: number[];
}

const handleDateChange = (date: Date | null) => {};

const handleDownload = () => {};

const UsersPage: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let analyticsData = await newSignupAnalytics(
        selectedDateRange[0],
        selectedDateRange[1]
      );

      setData(analyticsData.data);
    } catch (error: any) {
      errorHandler(error, navigate);
    }
  };

  const handleSearch = (searchParams: {
      keyword?: string;
      startDate?: dayjs.Dayjs | null;
      endDate?: dayjs.Dayjs | null;
    }) => {
      setSelectedDateRange([searchParams.startDate || null, searchParams.endDate || null]);
    };

  const title = "New Signups";
  return (
    <>
      <Header title={title}></Header>
      {/* <FilterBar
        reportDownloadLink="http://127.0.0.1:8000/api/credits/company-credits"
        filterLink="http://127.0.0.1:8000/api/company/active-list"
        filterName="Company Name"
      ></FilterBar> */}
      {/* <ReportFilters
        handleDateChange={handleDateChange}
        handleDownload={handleDownload}
        selectedDate={null}
        isDownloading={true}
      ></ReportFilters> */}
      <HeaderSearchBar
      onSearch={handleSearch}
      />
      <Box sx={{ width: "100%", height: "auto", padding: "1%" }}>
        <Card>
          <CardBody>
            <Chart
              type="line"
              categories={data.map(({ date }) => date)}
              values={data.map(({ total_count }) => total_count)}
            ></Chart>
          </CardBody>
        </Card>
      </Box>
      <Box sx={{ width: "100%", height: "auto", padding: "1%" }}>
        <Card>
          <CardBody>
            <NewSignUpsTable></NewSignUpsTable>
          </CardBody>
        </Card>
      </Box>
    </>
  );
};

export default UsersPage;
