import React from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
  
    const firstPageButton = (
      <Button
        key={1}
        variant={1 === currentPage ? 'solid' : 'outline'}
        onClick={() => handlePageChange(1)}
      >
        1
      </Button>
    );
  
    const lastPageButton = (
      <Button
        key={totalPages}
        variant={totalPages === currentPage ? 'solid' : 'outline'}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Button>
    );

    if (totalPages === 1) {
      buttons.push(
        <Button
          key={1}
          variant={1 === currentPage ? 'solid' : 'outline'}
          onClick={() => handlePageChange(1)}
        >
          1
        </Button>
      );
      return buttons;
    }
  
    if (currentPage <= 3) {
      const pagesToIterate = Math.min(totalPages, 5);
      for (let page = 1; page <= pagesToIterate; page++) {
        buttons.push(
          <Button
            key={page}
            variant={page === currentPage ? 'solid' : 'outline'}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        );
      }
      if (totalPages > 5) {
        buttons.push(<span key="dots-end">...</span>);
        buttons.push(lastPageButton);
      }
    } else if (currentPage >= totalPages - 2) {
      buttons.push(firstPageButton);
      buttons.push(<span key="dots-start">...</span>);
      for (let page = totalPages - 4; page <= totalPages - 1; page++) {
        buttons.push(
          <Button
            key={page}
            variant={page === currentPage ? 'solid' : 'outline'}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        );
      }
    } else {
      buttons.push(firstPageButton);
      if (currentPage >= 4) {
        buttons.push(<span key="dots-start">...</span>);
      }
      for (let page = currentPage - 1; page <= currentPage + 1; page++) {
        buttons.push(
          <Button
            key={page}
            variant={page === currentPage ? 'solid' : 'outline'}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        );
      }
      if (currentPage < totalPages - 3) {
        buttons.push(<span key="dots-end">...</span>);
      }
      buttons.push(lastPageButton);
    }
  
    return buttons;
  };
  
  

  return (
    <Box mt={4} display="flex" justifyContent="center" backgroundColor="white" padding="5px 0">
      <ButtonGroup>
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {'<'}
        </Button>
        {renderPaginationButtons()}
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {'>'}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default Pagination;