import { AxiosResponse } from "axios"
import React, {useRef, useState} from "react"

interface DownloadFileProps {
    readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly getFileName: () => string;
}

interface DownloadedFileInfo{
    readonly download: () => Promise<void>;
    readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
    readonly name: string | undefined;
    readonly url: string | undefined;
}

export const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError, 
    getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
    const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<string>();
    const [name, setFileName] = useState<string>();
    const [link, setLink] = useState<HTMLAnchorElement>();

    const download =async () => {
        try {
            preDownloading();
            const { data } = await apiDefinition();
            console.log(data);
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            // setLink(document.createElement('a'));
            link.href = url;
            link.setAttribute('download', 'ahsdjksdf.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);


            // setFileUrl(url);
            // setFileName(getFileName());

            // console.log(url);
            // ref.current?.click()
            postDownloading();
            // URL.revokeObjectURL(url);
        }
        catch(error){
            onError();
        }
    };

    return { download, ref, url, name };
} 