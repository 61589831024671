import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  AlertTitle,
} from "@chakra-ui/react";
import { newSignups} from "../../apis/ApiService";
import moment from "moment";
import { errorHandler } from "../../services/ErrorHandler";
import { useNavigate } from "react-router-dom";

interface DataItem {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  date_of_birth: number;
  email: string;
  gender: number;
  created_at: string;
  account_locked: number;
}

const NewSignUpsTable: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data based on the selected tab
  const fetchData = async () => {
    // setIsLoading(true);

    try {
      let signupData = await newSignups(
        new Date("2023-04-01 00:00:00"),
        new Date("2023-04-31 23:59:59")
      );

      console.error(signupData.data);
      setData(signupData.data);
    } catch (error: any) {
      errorHandler(error, navigate);
    }

    // setIsLoading(false);
  };

  return (
    <Box backgroundColor="White">
      <TableContainer>
        <Table colorScheme="white">
          <Thead
            backgroundColor="Orange"
            fontStyle="Bold"
            height="70"
            textColor="White"
          >
            <Th>S / N</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Display Name</Th>
            <Th>Date of Birth</Th>
            <Th>Email Address</Th>
            <Th>Gender</Th>
            <Th>Created At</Th>
            <Th>Account Locked</Th>
          </Thead>
          <Tbody>
            {data.map((item, index) => (
              <Tr key={item.id}>
                <Td>{index + 1}</Td>
                <Td>{item.first_name}</Td>
                <Td>{item.last_name}</Td>
                <Td>{item.display_name}</Td>
                <Td>{item.date_of_birth}</Td>
                <Td>{item.email}</Td>
                <Td>{item.gender}</Td>
                <Td>{item.created_at}</Td>
                <Td>{item.account_locked}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NewSignUpsTable;
