import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import React from "react";
import { useRef, useLayoutEffect, useState } from "react";
import { getUserInfo, logoutApi } from "../../apis/ApiService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { errorHandler } from "../../services/ErrorHandler";

interface MenuItemProps {
  width: string;
}

function MenuItems(props: MenuItemProps) {
  const menuListRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState<string>("");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const navigate = useNavigate();
  const userRole = getUserInfo().role_id;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (menuListRef.current) {
      const drawerWidth =
        menuListRef.current.parentElement?.parentElement?.clientWidth;
      if (drawerWidth) {
        setMenuWidth(`${parseInt(props.width) * (drawerWidth / 100)}px`);
      }
    }
  }, [props.width]);

  const handleLoggedOut = async () => {
    setIsLoggedOut(false);

    try {
      await logoutApi();
      setIsLoggedOut(true);
      dispatch(logout());
      navigate("/login");
    } catch (error: any) {
      errorHandler(error, navigate);
      setIsLoggedOut(false);
    }
  };

  const renderInternalMenu = () => (
    <>
      <MenuOptionGroup title="Internal">
        <MenuItem onClick={() => navigate("/company")}>Company</MenuItem>
        <MenuItem onClick={() => navigate("/jobs")}>
          Completed Jobs Analytics
        </MenuItem>
        <MenuItem onClick={() => navigate("/referral-report")}>
          Completed Job Referral Report
        </MenuItem>
        <MenuItem onClick={() => navigate("/new-signups")}>
          New Signup Report
        </MenuItem>
        <MenuItem onClick={() => navigate("/payment-report")}>
          Payment Report
        </MenuItem>
        <MenuItem onClick={() => navigate("/soa")}>Statement of Accounts</MenuItem>
        <MenuItem onClick={() => navigate("/location-summary")}>
          Location Summary
        </MenuItem>
      </MenuOptionGroup>
      <MenuDivider />
    </>
  );

  const renderExternalMenu = () => (
    <>
      <MenuOptionGroup title="External">
        <MenuItem as="a" href="#">
          External menu 1
        </MenuItem>
        <MenuItem as="a" href="#">
          External menu 2
        </MenuItem>
      </MenuOptionGroup>
      <MenuDivider />
    </>
  );

  return (
    <Menu defaultIsOpen closeOnSelect={false} closeOnBlur={false}>
      <MenuList ref={menuListRef} minWidth={menuWidth} style={{ borderStyle: "none" }}>
        <MenuOptionGroup>
          <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
        </MenuOptionGroup>
        {userRole === 1 && (
          <>
            {renderInternalMenu()}
            {renderExternalMenu()}
          </>
          )}
        {userRole === 2 && renderInternalMenu()}
        {(userRole === 3 || userRole === 4 || userRole === 5) && renderExternalMenu()}
        <MenuOptionGroup>
          <MenuItem as="a" onClick={handleLoggedOut}>
            Logout
          </MenuItem>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default MenuItems;

