import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import CardTable from "../components/molecules/CardTable";
import Pagination from "../components/atoms/Pagination";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import { Progress } from "@chakra-ui/react";
import dayjs from "dayjs";
import { downloadLocationSummarySOA } from "../apis/ApiService";
import { locationSummary } from "../apis/ApiService";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router";
import { errorHandler } from "../services/ErrorHandler";

interface DataItem {
  id: number;
  company_name: string;
  location_id: number;
  location_name: string;
  available_credits: number;
  total_credits_used: number;
  total_hours: string;
  assigned_credits: number;
  year: string;
  month: string;
  week: string;
}

export const SOALocationSummaryPage = () => {
  const navigate = useNavigate();
  const title = "Location Summary";
  const [selectedDateRange, setSelectedDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([null, null]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedCompanyName, setSelectedCompanyName] = useState<string | null>(
    null
  );
  const [selectedReportType, setSelectedReportType] = useState<number | null>(
    0
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState<DataItem[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [hoveredLocationId, setHoveredLocationId] = useState<number | null>(
    null
  );

  const handleSearch = (searchParams: {
    keyword?: string;
    startDate?: dayjs.Dayjs | null;
    endDate?: dayjs.Dayjs | null;
    companyId?: number | null;
    reportName?: string | null;
    reportType?: number | null;
  }) => {
    setSelectedDateRange([
      searchParams.startDate || null,
      searchParams.endDate || null,
    ]);
    setSelectedCompanyName(searchParams.reportName || null);
    setSelectedReportType(searchParams.reportType || null);
    setSelectedCompanyId(searchParams.companyId || null);
    setCurrentPage(1);
  };

  const handleDownload = async () => {
    if (isDownloading) {
      return;
    }

    try {
      setIsDownloading(true);
      let downloadData = await downloadLocationSummarySOA(
        selectedCompanyId,
        selectedCompanyName,
        selectedReportType,
        selectedDateRange[0],
        selectedDateRange[1]
      );

      if (downloadData) {
        // const singaporeTimeZone = "Asia/Singapore";
        const currentDate = new dayjs.Dayjs();
        const dateParam = currentDate.format("YYYY-MM-DD");
        const fileName = `SOA-${selectedCompanyName}-${dateParam}.xlsx`;

        const blob = new Blob([downloadData], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, fileName);
      }
    } catch (error: any) {
      errorHandler(error, navigate);
    } finally {
      setIsDownloading(false);
    }
  };

  const fetchData = async (
    page: number,
    companyId: number | null,
    selectedDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    if (selectedCompanyId != null) {
      try {
        setLoading(true); // Set loading to true while fetching data
        const data = await locationSummary(
          page,
          selectedDateRange[0],
          selectedDateRange[1],
          companyId,
          selectedReportType
        );
        setSummaryData(data.data);
        setTotalPages(data.last_page);
        console.log(data);
      } catch (error) {
        errorHandler(error, navigate);
      } finally {
        setLoading(false); // Set loading back to false after fetching is done
      }
    }
  };

  const handleRowClick = (companyId: number) => {
    navigate(
      `/soa/${companyId}/${selectedDateRange[0]}/${selectedDateRange[1]}}`
    );
  };

  const handleRowHover = (locationId: number) => {
    setHoveredLocationId(locationId);
  };

  const handleRowHoverLeave = () => {
    setHoveredLocationId(null);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchData(currentPage, selectedCompanyId, selectedDateRange);
  }, [currentPage, selectedCompanyId, selectedDateRange]);

  const columns = [
    { key: "company_name", header: "Company Name", flex: 3 },
    { key: "location_name", header: "Location Name", flex: 3 },
    { key: "total_hours", header: "Total Hours/Mins", flex: 3 },
    { key: "credits_added", header: "Credits Added", flex: 3 },
    { key: "credits_used", header: "Credits Used", flex: 3 },
  ];

  const renderRow = (item: DataItem) => (
    <Box
      onClick={() => handleRowClick(item.location_id)}
      onMouseEnter={() => handleRowHover(item.location_id)}
      onMouseLeave={handleRowHoverLeave}
      style={{
        cursor: "pointer",
        backgroundColor:
          hoveredLocationId === item.location_id
            ? "rgb(255, 186, 134)"
            : "white",
        color: hoveredLocationId === item.location_id ? "white" : "black",
        fontWeight: hoveredLocationId === item.location_id ? "600" : "300",
        transition: "background-color 0.3s ease-in-out",
        borderRadius: "md", // Optional: Add border radius for a card-like appearance
        boxShadow: hoveredLocationId === item.id ? "md" : "none", // Optional: Add a box shadow on hover
      }}
      borderRadius="md"
      marginBottom="10px"
    >
      <Flex align="center" padding={"12px"}>
        {/* <Text flex={3} marginRight="10px" overflowWrap={"break-word"}>{item.id}</Text> */}
        <Text key={"company_name"} flex={3} marginRight="10px">
          {item.company_name}
        </Text>
        {/* <Text flex={3} marginRight="10px" overflowWrap={"break-word"}>{item.location_id}</Text> */}
        <Text
          key={"location_name"}
          flex={3}
          marginRight="10px"
          overflowWrap={"break-word"}
        >
          {item.location_name}
        </Text>
        <Text
          key={"total_hours"}
          flex={3}
          marginRight="10px"
          overflowWrap={"break-word"}
        >
          {item.total_hours}
        </Text>
        <Text
          key={"assigned_credits"}
          flex={3}
          marginRight="10px"
          overflowWrap={"break-word"}
        >
          {item.assigned_credits}
        </Text>
        <Text
          key={"total_credits_used"}
          flex={3}
          marginRight="10px"
          overflowWrap={"break-word"}
        >
          {item.total_credits_used}
        </Text>
      </Flex>
    </Box>
  );

  return (
    <Flex flexDirection="column" width="100vw" height="100%">
      <Box position="fixed" top={0} width="100%" zIndex={10}>
        <Header title={title}></Header>
      </Box>
      <Box height="100%" padding="5%">
        <Box
          height="100%"
          padding="1%"
          background="white"
          borderTopRadius="10px"
          marginBottom="1%"
        >
          <HeaderSearchBar
            onSearch={handleSearch}
            handleDownload={handleDownload}
            placeholder="Enter Company Name"
            showDownloadButton
            showDateRangePicker
            isDownloading={isDownloading}
            showCompanyDropDown={true}
            showReportType={true}
          />
        </Box>

        {loading ? (
          <Progress size="xs" isIndeterminate /> // Show the progress while loading
        ) : (
          <>
            {summaryData.length === 0 ? ( // Check if there is no fetched data
              <Box
                height="100%"
                // padding="1%"
                background="white"
                borderRadius="10px"
                marginTop="1%"
                display="flex" // Use flexbox
                justifyContent="center" // Center horizontally
                alignItems="center" // Center vertically
              >
                <Text>No data available.</Text>
              </Box>
            ) : (
              <>
                <CardTable
                  data={summaryData}
                  columns={columns}
                  renderRow={renderRow}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};
