import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { ButtonState, Button } from "../atoms/button";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { Alert, Container } from "react-bootstrap";
import { companyCredits } from "../../apis/ApiService";
import { errorHandler } from "../../services/ErrorHandler";
import { useNavigate } from "react-router-dom";

interface DataItem {
  id: number;
  name: string;
  available_credits: number;
  locations: [Location];
  // id: number;
  // job_title: string;
  //payments: [Payments]
}

interface Location {
  id: number;
  company_id: number;
  name: string;
  available_credits: number;
}

// interface Payments {
//     id: number,
//     job_id: number,
//     slot_id: number,
//     processed_date: string
//     applicant:
// }

// interface Applicant {
//     id: number,
//     first_name: string,
//     last_name: string,
//     display_name: string,
//     referral_code: string,

// }

const CompanyCreditsTable: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Primary
  );

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const preDownloading = () => setButtonState(ButtonState.Loading);
  const postDownloading = () => setButtonState(ButtonState.Primary);
  const navigate = useNavigate();

  const onErrorDownloadingFile = () => {
    setButtonState(ButtonState.Primary);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getFileName = () => {
    return "_sample.xlsx";
  };

  const downloadFile = () => {
    return axios.get("http://127.0.0.1:8000/api/auth/credits/company-credits", {
      responseType: "blob",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Headers": "*",
      //   },
    });
  };

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: downloadFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadingFile,
    getFileName,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let creditsData = await companyCredits();
      console.log(creditsData);
      setData(creditsData.data);
    } catch (error: any) {
      errorHandler(error, navigate);
    }
  };
  return (
    <Container className="mt-5">
      <Alert variant="danger" show={showAlert}>
        Something went wrong. Please try again.
      </Alert>
      <Box backgroundColor="White">
        <Box>
          <a href={url} download={name} className="hidden" ref={ref} />
          <Button
            label="Download"
            buttonState={buttonState}
            onClick={download}
          />
        </Box>
        <TableContainer>
          <Table variant="striped" colorScheme="white">
            <Thead
              backgroundColor="Orange"
              fontStyle="Bold"
              height="70"
              textColor="White"
            >
              <Th>Company ID</Th>
              <Th>Company Name</Th>
              <Th>Company Available Credits</Th>
              <Th>Location ID</Th>
              <Th>Location Name</Th>
              <Th>Available Credits</Th>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.available_credits}</Td>
                  <Td>{item.locations[0].id}</Td>
                  <Td>{item.locations[0].name}</Td>
                  <Td>{item.locations[0].available_credits}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default CompanyCreditsTable;
