import { toast } from 'react-toastify';

let toastIsOpen = false;

export const errorHandler = (error: any, navigate: Function) => {
  if (error.response) {
    const status = error.response.status;

    if (status === 401) {
      navigate('/login');
      if (!toastIsOpen) {
        toast.error(error.response.data.error);
        toastIsOpen = true;
      }
    }else {
      console.error('Network error:', error);
      if (!toastIsOpen) {
        toast.error('An error occurred. Please try again later.');
        toastIsOpen = true;
      }
    }
  } 
};




  