import { useParams } from "react-router-dom";
import CompanyDetailsWithData from "../hooks/CompanyDetailsWithData";

const CompanyDetails = () => {
    const { companyId } = useParams<{ companyId: string }>();
  
    // Check if companyId is undefined or not
    if (companyId === undefined) {
      // Handle the case when companyId is undefined
      return <div>Company ID is missing.</div>;
    }
    const companyIdNumber = parseInt(companyId, 10);
  
    return <CompanyDetailsWithData companyId={companyIdNumber} />;
  };
  
  export default CompanyDetails;
