import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { paymentReport, paymentReportList } from "../apis/ApiService";
import PaymentChart from "../charts/PaymentChart";
import PaymentReportTable from "../components/tables/PaymentReportTable";
import Pagination from "../components/atoms/Pagination";
import PaymentReportFilter from "../components/PaymentReportFilter";
import { errorHandler } from "../services/ErrorHandler";
import { useNavigate } from "react-router-dom";

interface Data {
    total: number;
    limit: number;
    page: number;
    last_page: number;
    data: Payment[];
}

interface Payment {
    payment_id: number;
    full_name: string;
    job_id: number;
    location_name: string;
    clock_in: string;
    clock_out: string;
    total_hours: number;
    total_credits: number;
    processed_date: string;
  }

  interface PaymentReportProps {
    onLoginSuccess: () => void;
  }

  const PaymentReport: React.FC = () => {
    const title = "Payment Report";
    const [paymentData, setPaymentData] = useState<any[]>([]);
    const [paymentListData, setPaymentListData] = useState<Payment[]>([]);
    const [pagination, setPagination] = useState<Data>();
    const [isLoading, setIsLoading] = useState(false); // Initialize isLoading as false
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [keyword, setKeyword] = useState<string>('');
    const navigate = useNavigate();
  
    const handleKeywordChange = async (keyword: string, startDate: string, endDate: string) => {
        setCurrentPage(1);
        setIsLoading(true);
    
        const delay = 1000;
        await new Promise((resolve) => setTimeout(resolve, delay));
    
        try {
          const data = await paymentReport();
          setPaymentData(data.data);
    
          const listData = await paymentReportList(1, keyword, startDate, endDate);
          setPagination(listData.data);
          setPaymentListData(listData.data.data);
          setIsLoading(false);
    
          setTotalPages(listData.data.last_page);
          
        } catch (error: any) {
          errorHandler(error, navigate);
        }
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            setIsLoading(true);
            const data = await paymentReport();
            setPaymentData(data.data);
    
            const listData = await paymentReportList(currentPage, keyword);
            setPagination(listData.data);
            setPaymentListData(listData.data.data);
            setIsLoading(false);
    
            setTotalPages(listData.data.last_page);
          } catch (error: any) {
            errorHandler(error, navigate);
          }
        };
    
        if (currentPage !== pagination?.page) {
          fetchData();
        }
      }, [currentPage, keyword, navigate, pagination?.page]);
  
    const handlePageChange = (selectedPage: number) => {
      setCurrentPage(selectedPage);
    };
  
    return (
      <Flex flexDirection="column" width="100vw" height="100%">
        <Box position="fixed" top={0} width="100%" zIndex={10}>
          <Header title={title}></Header>
        </Box>
        <Box width="50%" alignSelf="center" margin="2% 5%" flex="auto" paddingTop="5%">
          <PaymentChart paymentData={paymentData}></PaymentChart>
        </Box>
        <Box height="100%" padding="5%">
          <PaymentReportFilter onKeywordChange={handleKeywordChange} />
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                zIndex: 1,
                height: "70vh", // Adjust the height as per your requirement
              }}
            >
              <Spinner size="xl" color="orange.500" />
            </Box>
          )}
          {!isLoading && (
            <>
              <PaymentReportTable paymentListData={paymentListData} isLoading={isLoading} />
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </>
          )}
        </Box>
      </Flex>
    );
  };
  
  export default PaymentReport;
  