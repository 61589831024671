import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TopPage from "./pages/TopPage";
import CompletedJobReferral from "./pages/CompletedJobReferral";
import LoginPage from "./pages/LoginPage";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { SOAPage } from "./pages/SOAPage";
import CreditPage from "./pages/CreditPage";
import UsersPage from "./pages/UsersPage";
import JobsPage from "./pages/JobsPage";
import PaymentReport from "./pages/PaymentReport";
import { CompanyList } from "./pages/CompanyList";
import CompanyDetails from "./pages/CompanyDetails";
import CompanyLocation from "./pages/CompanyLocation";
import { SOALocationSummaryPage } from "./pages/SOALocationSummaryPage";
import { useDispatch } from 'react-redux';
import { loginSuccess } from './redux/authSlice';
import ProtectedRoute from "./routes/ProtectedRoute";


const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "rgb(252 245 240)",
      },
    },
  },
});

function App() {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(!!token);

  useEffect(() => {
    if (token && user) {
      dispatch(loginSuccess(JSON.parse(user)));
    }
  }, [dispatch, token, user]);

  const handleLoginSuccess = () => {
      if (token && user) {
        dispatch(loginSuccess(JSON.parse(user)));
      }
    setIsLoggedIn(true);
  };

  return (
    <>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Routes>
              <>
                <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <TopPage />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/referral-report" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CompletedJobReferral />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/payment-report" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <PaymentReport />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/credits" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CreditPage />
                    </ProtectedRoute>
                  } />
                <Route path="/new-signups" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <UsersPage />
                    </ProtectedRoute>
                  } />
                <Route path="/company" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CompanyList />
                    </ProtectedRoute>
                  } />
                <Route path="/company/:companyId" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CompanyDetails />
                    </ProtectedRoute>
                  } />
                <Route path="/soa" 
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <SOAPage />
                    </ProtectedRoute>
                  } />
                <Route path="/location-summary" 
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <SOALocationSummaryPage />
                  </ProtectedRoute>
                } />
                <Route
                  path="/company/:companyId/location/:locationId"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CompanyLocation />
                    </ProtectedRoute>
                }
                />
                <Route path="/jobs" 
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <JobsPage />
                  </ProtectedRoute>
                } />
              </>
          </Routes>
        </ChakraProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="light"
        />
      </BrowserRouter>
    </>
  );
}

export default App;
