import React from "react";
import { Input, InputLeftElement, InputGroup } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from "dayjs";

interface CustomWeekPickerProps {
  weekNumber: string | undefined;
  // selectedDate: Date | null;
  handleWeekChange: (firstDayOfWeek: Date, weekNumber: string | number) => void;
  handleDateChange: (date: [Dayjs | null, Dayjs | null]) => void;
}

const CustomWeekPicker: React.FC<CustomWeekPickerProps> = ({
  weekNumber,
  handleWeekChange,
  handleDateChange,
}) => {
  const CustomInput = ({ value, onClick }: any) => (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <CalendarIcon color="gray.300" />
      </InputLeftElement>
      <Input
        value={value}
        onClick={onClick}
        backgroundColor="#E9E8E8"
        paddingLeft="2.5rem"
        borderRadius="10px"
        placeholder="Select a report date"
        width="auto"
        readOnly
      />
    </InputGroup>
  );

  //   console.log(selectedDate);
  return (
    <DatePicker
      value={weekNumber}
      customInput={<CustomInput />}
      showWeekNumbers={true}
      onWeekSelect={handleWeekChange}
      onChange={(date) => handleDateChange}
    />
  );
};

export default CustomWeekPicker;
