import React from "react";
import Select from "react-select";

export interface Options {
  value: number;
  label: string;
}

interface DropdownProps {
  dropdownValues: Options[] | undefined;
  selectedValue: any | null;
  handleSelection: (id: number | null, label: any | null) => void;
  placeholder?: string | null;
  isClearable?: boolean;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  dropdownValues,
  selectedValue,
  handleSelection,
  placeholder,
  isClearable = false,
}) => {
  function findId(idToSearch: any) {
    return dropdownValues?.filter((item) => {
      return item.value === idToSearch;
    });
  }

  return (
    <div style={{ minWidth: "300px" }}>
      <Select
        value={selectedValue !== null ? findId(selectedValue) : null}
        placeholder={placeholder}
        options={dropdownValues != null ? dropdownValues : []}
        onChange={(opt) =>
          handleSelection(
            opt != null ? opt!.value : null,
            opt != null ? opt!.label : null
          )
        }
        isClearable={isClearable}
      />
    </div>
  );
};

export default CustomDropdown;
