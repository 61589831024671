import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Progress } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { companyShow } from "../apis/ApiService"; 
import Header from "../components/Header";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import CardTable from "../components/molecules/CardTable";
import Pagination from "../components/atoms/Pagination";
import { companyLocationForJodCredit, companyDebitCreditHistory, downloadCompanyDebitCreditHistory } from "../apis/ApiService";
import CompanyChart from "../charts/CompanyChart";
import { QuestionIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { errorHandler } from "../services/ErrorHandler";


interface Company {
    id: number;
    name: string;
    url_company_logo: string;
    address: string;
    consumed_credits: number;
    available_credits: number;
    min_jod_credit_limit: number;
  }

interface CompanyLocationList {
    id: number;
    name: string;
    url_company_logo: string;
    address: string;
    job_credit_deduction: number,
    consumed_credits: number;
    available_credits: number;
    min_credit_limit: number;
}

interface CreditDebitHistory {
    id: number;
    transaction_date: string;
    location_name: string;
    description: string;
    debit: number,
    credit: number;
    available_credits: number;
    comment: string;
}

  const CompanyDetailsWithData = ({ companyId }: { companyId: number }) => {
    const title = 'Company Details';
    const [company, setCompany] = useState<Company | null>(null);

    const [data, setData] = useState<CompanyLocationList[]>([]);
    const [creditDebitHistoryData, setCreditDebitHistoryData] = useState<CreditDebitHistory[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [keyword, setKeyword] = useState<string | null>(null);
    const [creditDebitkeyword, setCreditDebitkeyword] = useState<string | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);
    const [hoveredLocationId, setHoveredLocationId] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const [imageError, setImageError] = useState(true);

    const navigate = useNavigate();

    
  
    useEffect(() => {
      const fetchCompanyData = async () => {
        try {
          const companyData = await companyShow(companyId);
          setCompany(companyData.data);
        } catch (error: any) {
          errorHandler(error, navigate);
        }
      };
  
      fetchCompanyData();
    }, [companyId]);

    const fetchCompanyLocationData = async (page: number, searchKeyword: string | null, company_id: number | null = null) => {
        try {
            setLoading(true);
            const companyLocationListData = await companyLocationForJodCredit(page, searchKeyword, companyId);
            setData(companyLocationListData.data.data);
            setTotalPages(companyLocationListData.data.last_page);
        } catch (error: any) {
          errorHandler(error, navigate);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
          if (activeTabIndex === 0){
            fetchCompanyLocationData(currentPage, keyword);
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentPage, keyword]);

      const fetchCreditDebitHistory = async (
        page: number,
        companyId: number,
        searchKeyword: string | null,
        startDate: dayjs.Dayjs | null, // Update the type here
        endDate: dayjs.Dayjs | null // Update the type here
      ) => {
        try {
            setLoading(true);
            const historyData = await companyDebitCreditHistory(page, companyId, startDate, endDate, searchKeyword);
            setCreditDebitHistoryData(historyData.data.data);
            
        } catch (error:any) {
          errorHandler(error, navigate);
        } finally {
            setLoading(false);
        }
    };


      useEffect(() => {
        if (activeTabIndex === 1){
            fetchCreditDebitHistory(currentPage, companyId, creditDebitkeyword, selectedDateRange[0], selectedDateRange[1]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, companyId, creditDebitkeyword, selectedDateRange[0], selectedDateRange[1]]);

    const columns = [
        { key: 'name', header: 'Location Name', flex: 2 },
        { key: 'address', header: 'Address', flex: 3 },
        { key: 'job_credit_deduction', header: 'Job Credit Deduction', flex: 1 },
        { key: 'consumed_credits', header: 'Consumed Credits', flex: 1 },
        { key: 'available_credits', header: 'Available Credits', flex: 1 },
        { key: 'min_credit_limit', header: 'Min. Credit Limit', flex: 1 },
      ];

      const debitCreditColumns = [
        { key: 'transaction_date', header: 'Transaction Date', flex: 1 },
        { key: 'location_name', header: 'Location Name', flex: 1 },
        { key: 'description', header: 'Description', flex: 2 },
        { key: 'credit', header: 'Credit', flex: 1 },
        { key: 'debit', header: 'Debit', flex: 1 },
        { key: 'available_credits', header: 'Available Credits', flex: 1 },
        { key: 'comments', header: 'Comments', flex: 3 },
    ];

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
      };

    const handleRowClick = (locationId: number) => {
        navigate(`/company/${companyId}/location/${locationId}`);
      };

      const handleRowHover = (locationId: number) => {
        setHoveredLocationId(locationId);
      };
      
      const handleRowHoverLeave = () => {
        setHoveredLocationId(null);
      };

      const handleSearchCompanyLocations = (searchParams: {
        keyword?: string;
        startDate?: dayjs.Dayjs | null;
        endDate?: dayjs.Dayjs | null;
      }) => {
      
        // Clear the previous timeout
        if (searchTimeout !== null) {
          clearTimeout(searchTimeout);
        }
      
        const newSearchTimeout = setTimeout(() => {
            setKeyword(searchParams.keyword || null);
            setCurrentPage(1);
        }, 1000); // Adjust the delay time as needed
      
        setSearchTimeout(newSearchTimeout);
      };
      

      const handleSearchCreditDebitHistory = (searchParams: {
        keyword?: string;
        startDate?: dayjs.Dayjs | null;
        endDate?: dayjs.Dayjs | null;
      }) => {

        // Clear the previous timeout
        if (searchTimeout !== null) {
            clearTimeout(searchTimeout);
          }
        
          const newSearchTimeout = setTimeout(() => {
            setCreditDebitkeyword(searchParams.keyword || null);
            setSelectedDateRange([searchParams.startDate || null, searchParams.endDate || null]);
            setCurrentPage(1);
            // fetchCreditDebitHistory(currentPage, companyId, creditDebitkeyword, selectedDateRange[0], selectedDateRange[1]);
          }, 1000); // Adjust the delay time as needed
        
          setSearchTimeout(newSearchTimeout);
      };

      const formatCredits = (credits: number) => {
        return credits.toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      };

      const handleTabChange = (tabIndex: number) => {
        setActiveTabIndex(tabIndex);
        
        console.log('Tab Changed: ', tabIndex);

        if (tabIndex === 1) {
          fetchCreditDebitHistory(
            currentPage,
            companyId,
            creditDebitkeyword,
            selectedDateRange[0],
            selectedDateRange[1]
          );
        } else if (tabIndex === 0 ) {
            fetchCompanyLocationData(currentPage, keyword);
        }
      };


    const renderRow = (item: CompanyLocationList) => (
        <Box
            onClick={() => handleRowClick(item.id)}
            onMouseEnter={() => handleRowHover(item.id)}
            onMouseLeave={handleRowHoverLeave}
            style={{
            cursor: 'pointer',
            backgroundColor: hoveredLocationId === item.id ? 'rgb(255, 186, 134)' : 'white',
            color: hoveredLocationId === item.id ? 'white' : 'black',
            fontWeight: hoveredLocationId === item.id ? '600' : '300',
            transition: 'background-color 0.3s ease-in-out',
            borderRadius: 'md', // Optional: Add border radius for a card-like appearance
            boxShadow: hoveredLocationId === item.id ? 'md' : 'none', // Optional: Add a box shadow on hover
            }}
            borderRadius="md"
            marginBottom="10px"
            padding="18px"
        >
        <Flex align="center">
            <Text flex={2}>
                {item.name}
            </Text>
            <Text flex={3}>{item.address}</Text>
            <Text flex={1} textAlign="center">
                {item.job_credit_deduction === 0 ? 'Company' : item.job_credit_deduction === 1 ? 'Location' : ''}
            </Text>
            <Text flex={1} textAlign="center">{formatCredits(item.consumed_credits)}</Text>
            <Text flex={1} color={item.available_credits < 0 ? 'red' : 'inherit'} textAlign="center">
                {formatCredits(item.available_credits)}
            </Text>
            <Text flex={1} textAlign="center">{item.min_credit_limit}</Text>
        </Flex>
        </Box>
      );

      const renderCreditDebitRow = (item: CreditDebitHistory) => (
        <Box
            style={{
            backgroundColor: 'white',
            color: 'black',
            fontWeight: '300',
            transition: 'background-color 0.3s ease-in-out',
            borderRadius: 'md',
            boxShadow: hoveredLocationId === item.id ? 'md' : 'none', // Optional: Add a box shadow on hover
            }}
            borderRadius="md"
            marginBottom="10px"
            padding="18px"
        >
        <Flex align="center">
            <Text flex={1}>
                {item.transaction_date}
            </Text>
            <Text flex={1}>{item.location_name}</Text>
            <Text flex={2}>{item.description}</Text>
            <Text flex={1}>{formatCredits(item.credit)}</Text>
            <Text flex={1}>{formatCredits(item.debit)}</Text>
            <Text flex={1} color={item.available_credits < 0 ? 'red' : 'inherit'} textAlign="center">
                {formatCredits(item.available_credits)}
            </Text>
            <Text flex={3}>{item.comment}</Text>
        </Flex>
        </Box>
      );

      const handleDownload = async () => {
        try {
            await downloadCompanyDebitCreditHistory(
                companyId,
                selectedDateRange[0],
                selectedDateRange[1],
                keyword,
              );
        } catch (error: any) {
          errorHandler(error, navigate);
        }
      };
  
    return (
        <Flex flexDirection="column" width="100vw" height="100%">
        <Box position="fixed" top={0} width="100%" zIndex={10}>
          <Header title={title}></Header>
        </Box>
        <Box height="100%" padding="5%">
        <Box
            mt="60px" 
            width="80%" 
            mx="auto" 
            p="20px"
            background="white"
            borderRadius="10px"
            boxShadow="md"
          >
            <Flex flexDirection="column">
              {/* Company Logo and Details */}
              {company && (
                <>
                    <Box alignItems="center" textAlign="center" mb={4}>
                        {imageError ? (
                            <QuestionIcon marginRight='5px' height='40px' width='40px' color='lightgray' />
                        ) : (
                            <Image
                            src={company.url_company_logo}
                            alt={company.name}
                            w="100%"
                            h="100%"
                            onError={() => setImageError(true)}
                            />
                        )}
                        <Text fontWeight="bold" fontSize={30}>{company.name}</Text>
                        <Text fontSize={24}>{company.address}</Text>
                    </Box>
                    <Box alignItems="center" textAlign="center" mb={4}>
                        <Text fontWeight="700">{"Consumed Credits: " + company.consumed_credits}</Text>
                        <Text fontWeight="700">{"Available Credits: " + company.available_credits}</Text>
                    </Box>
                </>
            )}
            </Flex>
            <Box
            mt="20px" 
            width="80%" 
            mx="auto" 
            p="20px"
            borderRadius="10px"
          >
            {/* Pie Chart */}
            {company && (
                <Box alignItems="center" flex="1">
                    <CompanyChart companyData={data}/>
                </Box>
            )}
          </Box>
          </Box>
        </Box>
        <Box height="100%" padding="5%" paddingTop='0'>
            <Tabs isFitted index={activeTabIndex} onChange={handleTabChange}>
                    <TabList sx={{ backgroundColor: 'white', borderTopRadius: '10px' }}>
                        <Tab sx={{ 
                          color: activeTabIndex === 0 ? 'white' : 'rgb(240 118 21)', 
                          backgroundColor: activeTabIndex === 0 ? 'rgb(240 118 21)' : 'white', 
                          fontWeight: activeTabIndex === 0 ? '700' : '300',
                          }}
                          >
                            Company Locations
                          </Tab>
                        <Tab sx={{ 
                          color: activeTabIndex === 1 ? 'white' : 'rgb(240 118 21)', 
                          backgroundColor: activeTabIndex === 1 ? 'rgb(240 118 21)' : 'white',
                          fontWeight: activeTabIndex === 1 ? '700' : '300',
                          }}
                          >
                            Credit-Debit History
                          </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                        <Box height="100%" padding='1%' background='white' borderTopRadius='10px'>
                            <HeaderSearchBar
                            onSearch={handleSearchCompanyLocations}
                            placeholder="Enter Company Name"
                            showKeywordField
                            // showDownloadButton
                        />
                        </Box>
                        {loading ? (
                            <Progress size='xs' isIndeterminate /> 
                            ) : (
                                <>
                                {data.length === 0 ? ( 
                                <Box
                                    height="100%"
                                    padding='1%'
                                    background='white'
                                    borderRadius='10px'
                                    marginTop='1%'
                                    display="flex" 
                                    justifyContent="center" 
                                    alignItems="center"
                                >
                                    <Text>No data available.</Text>
                                </Box>
                                ) : (
                                <>
                                    <CardTable data={data} columns={columns} renderRow={renderRow} />
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                </>
                                )}
                            </>
                            )}

                        </TabPanel>
                        <TabPanel>
                            <Box height="100%" padding='1%' background='white' borderTopRadius='10px'>
                                <HeaderSearchBar
                                onSearch={handleSearchCreditDebitHistory}
                                placeholder="Enter Location"
                                showKeywordField
                                showDownloadButton
                                showDateRangePicker
                                handleDownload={handleDownload}
                            />
                            </Box>
                            {loading ? (
                                <Progress size='xs' isIndeterminate /> 
                                ) : (
                                    <>
                                    {creditDebitHistoryData.length === 0 ? ( 
                                    <Box
                                        height="100%"
                                        padding='1%'
                                        background='white'
                                        borderRadius='10px'
                                        marginTop='1%'
                                        display="flex" 
                                        justifyContent="center" 
                                        alignItems="center"
                                    >
                                        <Text>No data available.</Text>
                                    </Box>
                                    ) : (
                                    <>
                                        <CardTable data={creditDebitHistoryData} columns={debitCreditColumns} renderRow={renderCreditDebitRow} />
                                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                    </>
                                    )}
                                </>
                                )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
        </Box>
      </Flex>
      );
    };
  
  export default CompanyDetailsWithData;