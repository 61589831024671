import axios from 'axios';
import HttpClient from '../services/HttpClient';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

export const ApiService = axios.create({
  baseURL: HttpClient,
});

export const getUserInfo = () => {
  const userInfo = localStorage.getItem('user');
  return userInfo ? JSON.parse(userInfo) : null;
};

export const loginApi = async (email: string, password: string) => {
  try {
    let params: { [key: string]: string } = {}; 

    if (email && password) {
      params.email = email;
      params.password = password;
    }

    const response = await ApiService.post(`/login`, params);

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));

    toast.success('Login successful!');

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const logoutApi = async () => {
  try {
    const token = localStorage.getItem('token');

    await ApiService.post(
      '/logout',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    localStorage.removeItem('token');

    localStorage.removeItem('user');

    toast.success('Logout successful!');

  } catch (error) {
    console.error('Logout error: ', error);
    throw error;
  }
};

export const locationList = async (company_id: number | undefined) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string } = {}; // Updated type assertion for params object

    if (company_id) {
      params.company_id = company_id.toString();
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/location/options?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error Fetching Data: ', error);
    throw error;
  }
}

export const downloadCompanySOA = async (companyId: Number | null, companyName?: String | null, locationId?: Number | null, report_end_date?:dayjs.Dayjs | null) => {

  try {

    let params: { [key: string]: string } = {}; // Updated type assertion for params object
    let dateTitle:string = report_end_date != null ? report_end_date.format('YYYY-MMMM') : moment(Date.now()).format("YYYY-MMMM");

    params.company_id = companyId != null ? companyId.toString() : '';

    if(locationId != null)
      params.location_id = locationId.toString();

    if(report_end_date != null) {
      const endDateParam = report_end_date.format('YYYY-MM-DD 23:59:59');
      params.report_end_date = endDateParam;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`auth/soa/export-soa?${queryString}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const jobsBlobData = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    const fileName = `SOA-${companyName?.toUpperCase()}-${dateTitle.toUpperCase()}.xlsx`;

    downloadFile(jobsBlobData, fileName);
    return jobsBlobData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const statementOfAccount = async (page: number, startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null, searchKeyword?: string | null, companyId?: Number | null, locationId?: Number | null) => {
  try {
    const singaporeTimeZone = 'Asia/Singapore';

    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (startDate) {
      const dateParam = startDate.format('YYYY-MM-DD 00:00:00');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = endDate.format('YYYY-MM-DD 23:59:59');
      params.report_end_date = endDateParam;
    }

    if (companyId) {
      params.company_id = companyId.toString();
    }

    if(locationId) {
      params.location_id = locationId.toString();
    }

    if (searchKeyword) {
      params.company_name = searchKeyword;
    }
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    // const clockInParam = clockInDate ? moment(clockInDate).tz(singaporeTimeZone).format('YYYY-MM-DD') : '';
    // const clockOutParam = clockInDate ? moment(clockInDate).tz(singaporeTimeZone).format('YYYY-MM-DD') : '';
    const response = await ApiService.get(`/auth/soa/list-soa?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const soaData = response.data.data;
    return soaData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const downloadLocationSummarySOA = async (company_id: number | null, companyName: string | null, summary_type: number | null, report_start_date:dayjs.Dayjs | null, report_end_date: dayjs.Dayjs | null) => {
  try {

    let params: { [key: string]: string } = {}; // Updated type assertion for params object
    let dateTitle: string;

    if(summary_type == null) 
      summary_type = 0;

    if(summary_type === 0) {
      dateTitle = report_end_date != null ? report_end_date.format('YYYY-MMMM') : moment(Date.now()).format("YYYY-MMMM");
    }
    else {
      if(report_start_date != null && report_end_date != null) 
        dateTitle = report_start_date?.format('YYYY-MMM-DD') + "-" + report_end_date?.format('YYYY-MMM-DD')
      else {
        const today = new Date();
        var firstDay = new Date(
          today.setDate(today.getDate() - today.getDay()),
        );

        var lastDay = new Date(
          today.setDate(today.getDate() - today.getDay() + 6),
        );

        dateTitle = moment(firstDay).format('YYYY-MMM-DD') + "-" + moment(lastDay).format('YYYY-MMM-DD');
      }

    }

    params.company_id = company_id != null ? company_id.toString() : '';
    params.summary_type = summary_type != null ? summary_type.toString() : '0';

    if(report_start_date != null) {
      const startDateParam = report_start_date.format('YYYY-MM-DD 00:00:00');
      params.report_start_date = startDateParam;
    }

    if(report_end_date != null) {
      const endDateParam = report_end_date.format('YYYY-MM-DD 23:59:59');
      params.report_end_date = endDateParam;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`auth/soa/location-summary-soa?${queryString}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const jobsBlobData = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    const fileName = `SOA-${companyName?.toUpperCase()}-${dateTitle?.toUpperCase()}.xlsx`;

    downloadFile(jobsBlobData, fileName);
    return jobsBlobData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const locationSummary = async (page: number, startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null, companyId?: Number | null, summaryType?: Number | null) => {
  try {
    const singaporeTimeZone = 'Asia/Singapore';

    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (startDate) {
      const dateParam = startDate.format('YYYY-MM-DD 00:00:00');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = endDate.format('YYYY-MM-DD 23:59:59');
      params.report_end_date = endDateParam;
    }

    if (companyId) {
      params.company_id = companyId.toString();
    }

    if (summaryType) {
      params.summary_type = summaryType.toString();
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    // const clockInParam = clockInDate ? moment(clockInDate).tz(singaporeTimeZone).format('YYYY-MM-DD') : '';
    // const clockOutParam = clockInDate ? moment(clockInDate).tz(singaporeTimeZone).format('YYYY-MM-DD') : '';
    const response = await ApiService.get(`/auth/soa/list-location-summary-soa?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const soaData = response.data.data;
    return soaData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const newSignupAnalytics = async (startDate: dayjs.Dayjs | null = null, endDate: dayjs.Dayjs | null = null) => {
  try {

    let params: { [key: string]: string } = {};

    if (startDate) {
      const dateParam = startDate.format('YYYY-MM-DD');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = endDate.format('YYYY-MM-DD');
      params.report_end_date = endDateParam;
    }
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`/auth/signups/new-signups-analytics?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}

export const completedJobAnalytics = async (startDate: dayjs.Dayjs | null = null, endDate: dayjs.Dayjs | null = null, company_id: string | null, location_id: string | null) => {
  try {

    let params: { [key: string]: string } = {};

    if (startDate) {
      const dateParam = startDate.format('YYYY-MM-DD');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = endDate.format('YYYY-MM-DD');
      params.report_end_date = endDateParam;
    }

    if (company_id) {
      params.company_id = company_id
    }

    if (location_id) {
      params.location_id = location_id;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`auth/jobs/completed-jobs-analytics?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const analyticsData = response.data; // Access the data property from the response
    return analyticsData.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const newSignups = async (startDate: Date | null, endDate: Date | null) => {
  try {
    const userTimeZone = moment.tz.guess(); // Get the user's timezone

    let params: { [key: string]: string } = {}; // Updated type assertion for params object

    if (startDate) {
      const dateParam = moment(startDate).tz(userTimeZone).format('YYYY-MM-DD');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = moment(endDate).tz(userTimeZone).format('YYYY-MM-DD');
      params.report_end_date = endDateParam;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`/auth/signups/new-signups?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newSignups = response.data; // Access the data property from the response
    return newSignups.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyMonthlyCredits = async (page: number, startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null, companyId?: Number | null, locationId?: Number | null) => {
  try {
    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (startDate) {
      const dateParam = startDate.format('YYYY-MM-DD');
      params.report_start_date = dateParam;
    }

    if (endDate) {
      const endDateParam = endDate.format('YYYY-MM-DD');
      params.report_end_date = endDateParam;
    }

    if (companyId) {
      params.company_id = companyId.toString();
    }

    if(locationId)
      params.location_id = locationId.toString();

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');
    const response = await ApiService.get(`/auth/credits/get-company-credits?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const credits = response.data;
    return credits.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyCredits = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await ApiService.get(`/auth/credits/list-company-credits?`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // const jobsData = response.data; // Access the data property from the response
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }

};

export const completedFirstJobReferral = async (selectedDate: dayjs.Dayjs | null = null) => {
  try {
    let params: { [key: string]: string } = {};

    if (selectedDate) {
      const dateParam = selectedDate.format('YYYY-MM-DD');
      params.date = dateParam;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`/auth/referral/first-job-referral-list?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const jobsData = response.data; // Access the data property from the response
    return jobsData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const completedNthJobReferral = async (selectedDate: dayjs.Dayjs | null = null) => {
  try {
    let params: { [key: string]: string } = {}; // Updated type assertion for params object

    if (selectedDate) {
      const dateParam = selectedDate.format('YYYY-MM-DD');
      params.date = dateParam;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`/auth/referral/nth-job-referral-list?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const jobsData = response.data; // Access the data property from the response
    console.log(jobsData);
    return jobsData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const paymentReportList = async (page: number, keyword: string | null = null, startDate: string | null = null, endDate: string | null = null) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (keyword) {
      params.key_word = keyword;
    }

    if (startDate) {
      params.start_date_time = startDate;
    }

    if (endDate) {
      params.end_date_time = endDate;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/payments/list-processed-payment-job?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const paymentData = response.data;
    return paymentData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

// Company
export const getCompanyOptions = async (keyword: string | null) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    if (keyword) {
      params.company_name = keyword;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/company/options?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const companyListData = response.data.data;
    return companyListData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyList = async (page: number, keyword: string | null = null) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (keyword) {
      params.company_name = keyword;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/company/index?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const companyListData = response.data.data;
    return companyListData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyShow = async (company_id: number) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: number } = {};

    params.company_id = company_id;

    const response = await ApiService.get(`/auth/company/show/${params.company_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const companyData = response.data;
    return companyData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


// Location
export const getLocationOptions = async (companyId: number | null) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    if (companyId) {
      params.company_id = companyId;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/location/options?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const locationListData = response.data.data;
    return locationListData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyLocationForJodCredit = async (page: number, keyword: string | null = null, company_id: number | null = null) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    params.page = page;

    if (keyword) {
      params.name = keyword;
    }

    if (company_id !== null) {
      params.company_id = company_id;
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const response = await ApiService.get(`/auth/location/listLocationForJodCreditByHqManager?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const companyLocationForJodCreditData = response.data;
    return companyLocationForJodCreditData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const companyDebitCreditHistory = async (
  page: number,
  company_id: number,
  startDate: dayjs.Dayjs | null = null,
  endDate: dayjs.Dayjs | null = null,
  keyword: string | null = null
) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    // params.company_id = company_id;

    params.page = page;

    if (keyword) {
      params.location_name = keyword;
    }

    if (startDate) {
      params.assign_start_date = startDate.toISOString(); // Convert to ISO string format
    }

    if (endDate) {
      params.assign_end_date = endDate.toISOString(); // Convert to ISO string format
    }

    const response = await ApiService.get(`/auth/credits/debit-credit-history/${company_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });

    const companyDebitCreditHistoryData = response.data;
    return companyDebitCreditHistoryData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

//Download APIs

const downloadFile = (blobData: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blobData);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const downloadCompletedFirstJobReferral = async (selectedDate: dayjs.Dayjs | null = null) => {
  try {

    let params: { [key: string]: string } = {}; // Updated type assertion for params object
    let dateTitle: string = '';

    if (selectedDate) {
      const dateParam = selectedDate.format('YYYY-MM-DD');
      params.date = dateParam;
      dateTitle = dateParam;
    } else {
      dateTitle = dayjs().format('YYYY-MM-DD');
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`auth/referral/first-job-referral?${queryString}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const jobsBlobData = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    const fileName = `referrals-${dateTitle}.xlsx`;

    downloadFile(jobsBlobData, fileName);
    return jobsBlobData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const downloadCompletedNthJobReferral = async (selectedDate: dayjs.Dayjs | null = null) => {
  try {

    let params: { [key: string]: string } = {}; // Updated type assertion for params object
    let dateTitle: string = '';

    if (selectedDate) {
      const dateParam = selectedDate.format('YYYY-MM-DD');
      params.date = dateParam;
      dateTitle = dateParam;
    } else {
      dateTitle = dayjs().format('YYYY-MM-DD');
    }

    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const token = localStorage.getItem('token');

    const response = await ApiService.get(`auth/referral/nth-job-referral?${queryString}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const jobsBlobData = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    const fileName = `referrals-${dateTitle}.xlsx`;

    downloadFile(jobsBlobData, fileName);
    return jobsBlobData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const downloadCompanyDebitCreditHistory = async (
  company_id: number,
  startDate: dayjs.Dayjs | null = null,
  endDate: dayjs.Dayjs | null = null,
  keyword: string | null = null
) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: string | number } = {};

    params.company_id = company_id;

    if (keyword) {
      params.location_name = keyword;
    }

    if (startDate) {
      params.assign_start_date = startDate.toISOString(); // Convert to ISO string format
    }

    if (endDate) {
      params.assign_end_date = endDate.toISOString(); // Convert to ISO string format
    }

    const response = await ApiService.get(`/auth/credits/debit-creditExport/${params.company_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
      responseType: 'arraybuffer',
    });

    const jobsBlobData = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    const fileName = `JOD_debit_credit_transactions_${Date.now()}.xlsx`;

    downloadFile(jobsBlobData, fileName);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const locationShow = async (id: number) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: number } = {};

    params.id = id;

    const response = await ApiService.get(`/auth/location/show/${params.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const locationData = response.data;
    return locationData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const locationCreditHistory = async (page: number, id: number) => {
  try {
    const token = localStorage.getItem('token');

    let params: { [key: string]: number } = {};

    params.id = id;

    params.page = page;

    const response = await ApiService.get(`/auth/location/credit-history/${params.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const locationCreditHistoryData = response.data;
    return locationCreditHistoryData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}



//Statistics

export const paymentReport = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await ApiService.get(`/auth/payments/payment-statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const paymentData = response.data; // Access the data property from the response
    console.log(paymentData);
    return paymentData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
