import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Flex,
    Menu,
    MenuGroup,
    MenuItem
  } from '@chakra-ui/react';
import React from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/react';
import logoImage from '../assets/images/Logo.png';
import MenuItems from './atoms/MenuItems';

function SideMenu() {
const { isOpen, onOpen, onClose } = useDisclosure()
const btnRef = React.useRef(null)

return (
    <>
    <Flex justifyContent="flex-start">
        <Button 
        ref={btnRef} backgroundColor="transparent" leftIcon={<HamburgerIcon boxSize={6}/>} size="lg" onClick={onOpen}>
        </Button>
    </Flex>
    
    <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
    >
        <DrawerOverlay />
        <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader><Image src={logoImage} alt='JOD' /></DrawerHeader>

        <DrawerBody style={{padding: 0}}>
            <div style={{position: 'relative', marginLeft: '3%'}}>
                <MenuItems width='120%'/>
            </div>
            <Menu>
                <MenuGroup>
                  <MenuItem as="a" href="#">
                    Logout
                  </MenuItem>
                </MenuGroup>
              </Menu>
        </DrawerBody>
        </DrawerContent>
    </Drawer>
    </>
)
};

export default SideMenu;