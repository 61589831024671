import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import {
  completedFirstJobReferral,
  completedNthJobReferral,
  downloadCompletedFirstJobReferral,
  downloadCompletedNthJobReferral,
} from "../../apis/ApiService";
import TabMenu from "../atoms/TabMenu";
import { saveAs } from "file-saver";
import dayjs from 'dayjs';
import HeaderSearchBar from "../molecules/HeaderSearchBar";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { errorHandler } from "../../services/ErrorHandler";

interface DataItem {
  id: number;
  company_id: number;
  user_location_id: number;
  job_title: string;
  payments: Payments[];
}

interface Payments {
  id: number;
  job_id: number;
  slot_id: number;
  processed_date: string;
  slot_user: SlotUser;
  applicant: Applicant;
}

interface SlotUser {
  actual_clock_in: string;
  actual_clock_out: string;
}

interface Applicant {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  email: string;
  v1_friend_referral_code: string;
  ambassador: Ambassador;
  created_at: string;
}

interface Ambassador {
  id: number | null;
  first_name: string | null;
  last_name: string | null;
  display_name: string | null;
  email: string | null;
  contact_number: string | null;
  bank_code: string | null;
  bank: Bank;
  bank_account_number: string | null;
  bank_account_name: string | null;
}

interface Bank {
  id: number | null;
  bank_name: string | null;
}

//Custom Table CSS
const tableStyles = css`
  th {
    color: white;
  }
`;

const CompletedJobReferralTable: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  // Fetch data based on the selected tab
  const fetchData = async (selectedDate: dayjs.Dayjs | null) => {
    setIsLoading(true);

    try {
      let newData;
      if (selectedTab === 0) {
        newData = await completedFirstJobReferral(selectedDate);
      } else if (selectedTab === 1) {
        newData = await completedNthJobReferral(selectedDate);
      }

      setData(newData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
        toast.error(error.response.data.error);
      } else {
        toast.error(error.response.data.error);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(selectedDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedDate]);

  const handleTabSelect = (index: number) => {
    setSelectedTab(index);
  };
  
  const handleDownload = async () => {
    if (isDownloading) {
      return;
    }

    try {
      setIsDownloading(true);

      let downloadData;
      if (selectedTab === 0) {
        downloadData = await downloadCompletedFirstJobReferral(selectedDate);
      } else if (selectedTab === 1) {
        downloadData = await downloadCompletedNthJobReferral(selectedDate);
      }
      // Add conditions for other tabs as needed

      if (downloadData) {
        // const singaporeTimeZone = "Asia/Singapore";
        const currentDate = new dayjs.Dayjs();
        const dateParam = selectedDate
          ? selectedDate.format("YYYY-MM-DD")
          : currentDate.format("YYYY-MM-DD");
        const fileName = `referrals-${dateParam}.xlsx`;

        const blob = new Blob([downloadData], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, fileName);
      }
    } catch (error: any) {
      errorHandler(error, navigate);
    } finally {
      setIsDownloading(false);
    }
  };

  // const formatCredits = (credits: number) => {
  //   return credits.toLocaleString('en-US', {
  //     style: 'decimal',
  //     maximumFractionDigits: 2,
  //     minimumFractionDigits: 2,
  //   });
  // };

  // const columns = [
  //       { key: 'first_name', header: 'First Name', flex: 2 },
  //       { key: 'last_name', header: 'Last Name', flex: 2 },
  //       { key: 'display_name', header: 'Display Name', flex: 3 },
  //       { key: 'account_id', header: 'Account ID', flex: 1 },
  //       { key: 'email', header: 'Email', flex: 3 },
  //       { key: 'date_of_registration', header: 'Date of Registration', flex: 2 },
  //       { key: 'job_id', header: 'Job ID', flex: 1 },
  //       { key: 'slot_id', header: 'Slot ID', flex: 1 },
  //       { key: 'actual_clock_in', header: 'Actual Clock In', flex: 2 },
  //       { key: 'actual_clock_out', header: 'Actual Clock Out', flex: 2 },
  //       { key: 'processed_date', header: 'Processed Date', flex: 2 },
  //       { key: 'referral_code', header: 'Referral Code', flex: 2 },
  //       { key: 'ambassador_email', header: 'Ambassador Email', flex: 3 },
  //       { key: 'ambassador_contact_no', header: 'Ambassador Contact No.', flex: 1 },
  //       { key: 'ambassador_account_id', header: 'Ambassador Account ID', flex: 1 },
  //       { key: 'ambassador_first_name', header: 'Ambassador First Name', flex: 2 },
  //       { key: 'ambassador_last_name', header: 'Ambassador Last Name', flex: 2 },
  //       { key: 'ambassador_display_name', header: 'Ambassador Display Name', flex: 3 },
  //       { key: 'ambassador_bank_name', header: 'Ambassador Bank Name', flex: 2 },
  //       { key: 'ambassador_bank_account_name', header: 'Ambassador Bank Account Name', flex: 3 },
  //       { key: 'ambassador_bank_account_no', header: 'Ambassador Bank Account No.', flex: 2 },
  //     ];

  //     const renderRow = (item: DataItem) => (
  //       <Box
  //           style={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           fontWeight: '300',
  //           transition: 'background-color 0.3s ease-in-out',
  //           borderRadius: 'md', 
  //           }}
  //           borderRadius="md"
  //           marginBottom="10px"
  //           padding="12px"
  //       >
  //       {item.payments.map((payment, index) => (
  //           <Flex key={index} align="center">
  //             <Text flex={2} marginRight="10px">{payment.applicant ? payment.applicant.first_name : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment.applicant ? payment.applicant.last_name : ''}</Text>
  //             <Text flex={3} marginRight="10px">{payment.applicant ? payment.applicant.display_name : ''}</Text>
  //             <Text flex={1} marginRight="10px">{payment.applicant ? payment.applicant.id : ''}</Text>
  //             <Text flex={3} marginRight="10px">{payment.applicant ? payment.applicant.email : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment.applicant ? payment.applicant.created_at : ''}</Text>
  //             <Text flex={1} marginRight="10px">{payment? payment.job_id : ''}</Text>
  //             <Text flex={1} marginRight="10px">{payment? payment.slot_id : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment? payment.slot_user.actual_clock_in : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment? payment.slot_user.actual_clock_out : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment? payment.processed_date : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment.applicant ? payment.applicant.v1_friend_referral_code : ''}</Text>
  //             <Text flex={3} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.email : ''}</Text>
  //             <Text flex={1} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.contact_number : ''}</Text>
  //             <Text flex={1} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.id : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.first_name : ''}</Text>
  //             <Text flex={2} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.last_name : ''}</Text>
  //             <Text flex={3} marginRight="10px">{payment.applicant.ambassador ? payment.applicant.ambassador.display_name : ''}</Text>
  //             <Text flex={2} >{payment.applicant.ambassador.bank ? payment.applicant.ambassador.bank.bank_name : ''}</Text>
  //             <Text flex={3}>{payment.applicant.ambassador ? payment.applicant.ambassador.bank_account_name : ''}</Text>
  //             <Text flex={2}>{payment.applicant.ambassador ? payment.applicant.ambassador.bank_account_number : ''}</Text>
  //           </Flex>
  //         ))}
  //       </Box>
  //     );

      const handleSearch = (searchParams: {
        keyword?: string;
        startDate?: dayjs.Dayjs | null;
        endDate?: dayjs.Dayjs | null;
      }) => {
        setSelectedDate(searchParams.startDate || null);
        fetchData(selectedDate);

        console.log('SELECTED SINGLE DATE: ', selectedDate);
      };

  return (
    <>
      <Flex flexDirection="column" width="100vw" height="100%">
      <Box height="100%" padding="5%">
      <Box
            mt="60px" 
            mx="auto" 
            p="20px"
            background="white"
            borderTopRightRadius="10px"
            borderTopLeftRadius="10px"
            boxShadow="md"
          >
      <HeaderSearchBar
      onSearch={handleSearch}
      showDownloadButton
      showDateRangePicker
      singleDatePicker={true}
      handleDownload={handleDownload}
      />
      </Box>
      <TabMenu
        title={["Completed First Job Referral", "Completed Nth Job Referral"]}
        onTabSelect={handleTabSelect}
        bg="orange"
        color="white"
      />
      <Box
        sx={{
          overflowX: "auto",
          height: "auto",
          minHeight: `calc(100% - 270px)`,
          backgroundColor: "white",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Adjust the opacity and color as needed
              zIndex: 1,
            }}
          >
            <Spinner size="xl" color="orange.500" />
          </Box>
        ) : (
          <Table key={selectedTab} variant="simple" css={tableStyles}>
            <Thead sx={{ backgroundColor: "orange" }}>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Display Name</Th>
              <Th>Account ID</Th>
              <Th>Email</Th>
              <Th>Date of Registration</Th>
              <Th>Job ID</Th>
              <Th>Slot ID</Th>
              <Th>Actual Clock In</Th>
              <Th>Actual Clock Out</Th>
              <Th>Processed Date</Th>
              <Th>Referral Code</Th>
              <Th>Ambassador Email</Th>
              <Th>Ambassador Contact No.</Th>
              <Th>Ambassador Account ID</Th>
              <Th>Ambassador First Name</Th>
              <Th>Ambassador Last Name</Th>
              <Th>Ambassador Display Name</Th>
              <Th>Ambassador Bank Name</Th>
              <Th>Ambassador Bank Account Name</Th>
              <Th>Ambassador Bank Account No</Th>
            </Thead>
            <Tbody sx={{ fontWeight: "300" }}>
              {data.map((item) =>
                item.payments.map((payment) => (
                  <Tr key={payment.id}>
                    <Td>{payment.applicant.first_name}</Td>
                    <Td>{payment.applicant.last_name}</Td>
                    <Td>{payment.applicant.display_name}</Td>
                    <Td>{payment.applicant.id}</Td>
                    <Td>{payment.applicant.email}</Td>
                    <Td>{payment.applicant.created_at}</Td>
                    <Td>{payment.job_id}</Td>
                    <Td>{payment.slot_id}</Td>
                    <Td>{payment.slot_user.actual_clock_in}</Td>
                    <Td>{payment.slot_user.actual_clock_out}</Td>
                    <Td>{payment.processed_date}</Td>
                    <Td>{payment.applicant.v1_friend_referral_code}</Td>
                    <Td>{payment.applicant.ambassador.email}</Td>
                    <Td>{payment.applicant.ambassador.contact_number}</Td>
                    <Td>{payment.applicant.ambassador.id}</Td>
                    <Td>{payment.applicant.ambassador.first_name}</Td>
                    <Td>{payment.applicant.ambassador.last_name}</Td>
                    <Td>{payment.applicant.ambassador.display_name}</Td>
                    <Td>{payment.applicant.ambassador.bank?.bank_name}</Td>
                    <Td>{payment.applicant.ambassador.bank_account_name}</Td>
                    <Td>{payment.applicant.ambassador.bank_account_number}</Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        )}
      </Box>
      </Box>
      </Flex>
    </>
  );
};

export default CompletedJobReferralTable;
