import React, { useState, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  IconButton,
  FormControl,
  FormLabel,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import CustomDatePicker from "../atoms/CustomDatePicker";
import dayjs from "dayjs";
import CustomDropdown from "../atoms/CustomDropdown";
import { Options } from "../atoms/CustomDropdown";
import { getCompanyOptions, getLocationOptions } from "../../apis/ApiService";
import { useEffect } from "react";
import CustomWeekPicker from "../atoms/CustomWeekPicker";
import { useNavigate } from "react-router-dom";

interface HeaderSearchBarProps {
  onSearch: (searchParams: {
    keyword?: string;
    startDate?: dayjs.Dayjs | null; // Use Dayjs type here
    endDate?: dayjs.Dayjs | null; // Use Dayjs type here
    companyId?: number | null;
    locationId?: number | null;
    reportType?: number | null;
    reportName?: string | null;
  }) => void;
  placeholder?: string;
  showDateRangePicker?: boolean;
  showKeywordField?: boolean;
  showDownloadButton?: boolean;
  isDownloading?: boolean;
  singleDatePicker?: boolean;
  showWeekPicker?: boolean;
  showCompanyDropDown?: boolean;
  showLocationDropDown?: boolean;
  showReportType?: boolean;
  handleDownload?: () => void;
}

const HeaderSearchBar: React.FC<HeaderSearchBarProps> = ({
  onSearch,
  placeholder,
  showWeekPicker = false,
  showDateRangePicker = false,
  showKeywordField = false,
  showDownloadButton = false,
  singleDatePicker = false,
  handleDownload,
  isDownloading = false,
  showCompanyDropDown = false,
  showLocationDropDown = false,
  showReportType = false,
  // handleDropdownSelection
}) => {
  const [keyword, setKeyword] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([null, null]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<string | undefined>(
    undefined
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null
  );
  const [selectedReportType, setSelectedReportType] = useState<number | null>(
    0
  );
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const isCompanyLoading = useRef(true);
  const isLocationLoading = useRef(true);
  const [companyOptions, setCompanyOptions] = useState<Options[]>([]);
  const [locationOptions, setLocationOptions] = useState<Options[]>([]);
  const reportTypeOptions: Options[] = [
    { value: 0, label: "Monthly" },
    { value: 1, label: "Weekly" },
  ];

  const handleDateChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    // setSelectedDateRange(dates);
    const [startDate, endDate] = dates;

    if (singleDatePicker) {
      setSelectedDate(startDate);
    } else {
      setSelectedDateRange(dates);
    }

    onSearch({
      keyword,
      startDate: startDate,
      endDate: endDate,
      companyId: selectedCompanyId,
      locationId: selectedLocationId,
      reportType: selectedReportType,
      reportName: selectedName,
    });
  };

  const handleWeekChange = (
    firstDayofWeek: Date,
    weekNumber: string | number
  ) => {
    setSelectedWeek(weekNumber.toString());
  };

  const handleCompanyDropdownSelection = (
    id: number | null,
    label: string | null
  ) => {
    setSelectedCompanyId(id);
    setSelectedName(label);
    setSelectedLocationId(null);
    onSearch({
      keyword: keyword,
      startDate: selectedDateRange[0],
      endDate: selectedDateRange[1],
      companyId: id,
      reportName: label,
    });
  };

  const handleLocationDropdownSelection = (
    id: number | null,
    label: string | null
  ) => {
    setSelectedLocationId(id);
    setSelectedName(label);
    onSearch({
      keyword: keyword,
      startDate: selectedDateRange[0],
      endDate: selectedDateRange[1],
      companyId: selectedCompanyId,
      locationId: id,
      reportName: label,
    });
  };

  const handleReportTypeSelection = (
    id: number | null,
    label: string | null
  ) => {
    setSelectedReportType(id);
    onSearch({
      keyword: keyword,
      startDate: selectedDateRange[0],
      endDate: selectedDateRange[1],
      companyId: selectedCompanyId,
      reportName: selectedName,
      locationId: selectedLocationId,
      reportType: id,
    });
  };

  const onDownload = () => {
    if (handleDownload !== undefined) handleDownload();
  };

  // Fetch company options data
  const fetchCompanyData = async () => {
    // setIsCompanyLoading(true);
    try {
      isCompanyLoading.current = true;
      const companies = await getCompanyOptions("");
      setCompanyOptions(companies);
      isCompanyLoading.current = false;
    } catch (error: any) {
    }
  };

  // Fetch location options data
  const fetchLocationData = async () => {
    if (selectedCompanyId != null) {
      try {
        isCompanyLoading.current = true;
        const locations = await getLocationOptions(selectedCompanyId);
        setLocationOptions(locations);
        isLocationLoading.current = false;
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  useEffect(() => {
    if (showCompanyDropDown) fetchCompanyData();
  }, [showCompanyDropDown]);

  useEffect(() => {
    if (showLocationDropDown) fetchLocationData();
  }, [showLocationDropDown, selectedCompanyId]);

  return (
    <Flex alignItems="center" justifyContent="space-between">
      {showCompanyDropDown && (
        <Flex alignItems="baseline">
          {" "}
          {/* Align elements along the baseline */}
          <FormControl>
            <FormLabel>Companies</FormLabel>
            <CustomDropdown
              // isLoading = {isCompanyLoading.current}
              dropdownValues={
                companyOptions != null ? companyOptions : undefined
              }
              selectedValue={selectedCompanyId}
              handleSelection={handleCompanyDropdownSelection}
              placeholder={"Select a Company"}
            />
          </FormControl>
        </Flex>
      )}

      {showLocationDropDown && (
        <Flex alignItems="baseline">
          {" "}
          {/* Align elements along the baseline */}
          <FormControl>
            <FormLabel>Locations</FormLabel>
            <CustomDropdown
              // isLoading = {isCompanyLoading.current}
              dropdownValues={
                locationOptions != null ? locationOptions : undefined
              }
              selectedValue={selectedLocationId}
              handleSelection={handleLocationDropdownSelection}
              placeholder={"Select a Location"}
              isClearable={true}
            />
          </FormControl>
        </Flex>
      )}
      {showReportType && (
        <Flex alignItems="baseline">
          {" "}
          {/* Align elements along the baseline */}
          <FormControl>
            <FormLabel>Report Type</FormLabel>
            <CustomDropdown
              dropdownValues={
                reportTypeOptions != null ? reportTypeOptions : undefined
              }
              selectedValue={selectedReportType}
              handleSelection={handleReportTypeSelection}
              placeholder={"Select Report Type"}
            />
          </FormControl>
        </Flex>
      )}

      {showWeekPicker && (
        <Box mr={4}>
          <FormControl>
            <FormLabel>Date Range</FormLabel>
            <Flex flexDirection="row">
              <CustomWeekPicker
                weekNumber={selectedWeek}
                handleWeekChange={handleWeekChange}
                handleDateChange={handleDateChange}
              />
            </Flex>
          </FormControl>
        </Box>
      )}

      {showDateRangePicker && (
        <Box mr={4}>
          <FormControl>
            <FormLabel>Date Range</FormLabel>
            <Flex flexDirection="row">
              <CustomDatePicker
                selectedDate={
                  singleDatePicker
                    ? [selectedDate, selectedDate]
                    : selectedDateRange
                }
                handleDateChange={handleDateChange}
                singleDatePicker={singleDatePicker}
              />
            </Flex>
          </FormControl>
        </Box>
      )}

      {showKeywordField && (
        <Flex alignItems="baseline">
          {" "}
          {/* Align elements along the baseline */}
          <FormControl>
            <FormLabel>Keyword</FormLabel>
            <Input
              value={keyword}
              onChange={(e) => {
                const newKeyword = e.target.value;
                setKeyword(newKeyword);
                onSearch({
                  keyword: newKeyword,
                  startDate: selectedDateRange[0],
                  endDate: selectedDateRange[1],
                  companyId: selectedCompanyId,
                });
              }}
              placeholder={placeholder || "Enter keyword"}
              width="350px"
            />
          </FormControl>
        </Flex>
      )}

      {/* {showDownloadButton && (
        <Box height="100%" alignSelf="flex-end">
          <IconButton
          icon={<DownloadIcon />}
          colorScheme="blue"
          aria-label="Download"
          ml={2}
          onClick={handleDownload}
        />
        </Box>
      )} */}

      {showDownloadButton && (
        <Box height="100%" alignSelf="flex-end">
          <Button
            // icon={<DownloadIcon />}
            colorScheme="blue"
            // aria-label="Download"
            disabled={isDownloading}
            ml={2}
            onClick={onDownload}
          >
            {isDownloading ? "Loading.." : "Download"}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default HeaderSearchBar;
