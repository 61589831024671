import React from "react";
import { Tabs, TabList, Tab } from '@chakra-ui/react';

interface TabProps {
  title: string[];
  color?: string;
  bg?:string;
}

const TabMenu = ({ title, onTabSelect, color, bg }: TabProps & { onTabSelect: (index: number) => void }) => {
  const selectedTabStyle = color ? { _selected: { backgroundColor: bg, color: color, fontWeight: "bold" } } : {};

  return (
    <Tabs isFitted variant='enclosed'>
      <TabList sx={{height: "65px"}}>
        {title.map((tabTitle, index) => (
          <>
          <Tab key={index} onClick={() => onTabSelect(index)} {...selectedTabStyle} sx={{ fontWeight: "700", color: bg, bg: "#EEEEEE" }}>
            {tabTitle}
          </Tab>
          </>
        ))}
      </TabList>
    </Tabs>
  );
};

export default TabMenu;