import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from 'react-apexcharts';

interface CompanyChartProps {
    companyData: any[];
}

const CompanyChart: React.FC<CompanyChartProps> = ({ companyData }) => {
    
    const locationAvailableCredits = companyData ? companyData.map(item => ({
        x: item.name,
        y: item.available_credits,
    })) : [];

    const locationConsumedCredits = companyData ? companyData.map(item => ({
        x: item.name,
        y: item.consumed_credits,
    })) : [];

    const allZero = companyData.every(item => item.available_credits === 0 && item.consumed_credits === 0);
      
    const chartOptions: ApexOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false, 
            },
            foreColor: 'white',
            },
            xaxis: {
                categories: companyData ? companyData.map(item => item.name) : [],
                labels: {
                    rotate: -35, // Rotate labels by -45 degrees
                    style: {
                        fontSize: '9px',
                    },
                },
            },
            yaxis: {
            labels: {
                formatter: (value: number) => {
                    if (value === 0) {
                        return '0';
                      }
                return Math.round(value).toString(); // Format the labels as integers
                },
            },
            min: allZero ? 0 : undefined,
            max: allZero ? 5 : undefined,
            },
            dataLabels: {
            enabled: false,
            },
            title: {
            text: "Company Credit Summary",
            align: "center",
            style: {
                fontSize: "25px",
                fontWeight: "bold",
                color: "#fff",
            },
            },
            legend: {
            position: "top",
            horizontalAlign: "center",
            offsetY: -10,
            markers: {
                fillColors: ['#1f77b4', '#ff7f0e'],
            },
            },
        };
    
      const chartSeries = [
        {
            name: 'Available Credits',
            data: locationAvailableCredits,
        },
        {
            name: 'Consumed Credits',
            data: locationConsumedCredits,
            color: '#ff7f0e',
        },
        ];
    
      return (
        <div style={{ padding: '5%', background: '#324562', borderRadius: '20px'}}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={'auto'} />
        </div>
      );
  };

export default CompanyChart;