// import React from "react";
// import { Input, InputLeftElement, InputGroup } from '@chakra-ui/react'
// import { CalendarIcon } from '@chakra-ui/icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// interface CustomDatePickerProps {
//     selectedDate: Date | null;
//     handleDateChange: (date: Date | null) => void;
//   }
  
//   const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
//     selectedDate,
//     handleDateChange,
//   }) => {
//     const CustomInput = ({ value, onClick }: any) => (
//       <InputGroup>
//         <InputLeftElement pointerEvents="none">
//           <CalendarIcon color="gray.300" />
//         </InputLeftElement>
//         <Input
//           value={value}
//           onClick={onClick}
//           backgroundColor="#E9E8E8"
//           paddingLeft="2.5rem"
//           borderRadius="10px"
//           placeholder="Select a report date"
//           width="auto"
//           readOnly
//         />
//       </InputGroup>
//     );

//     console.log(selectedDate);
//     return (
//       <DatePicker
//         selected={selectedDate}
//         onChange={handleDateChange}
//         customInput={<CustomInput />}
//         dateFormat="MM/dd/yyyy"
//         showPopperArrow={false}
//         popperPlacement="bottom-start"
//       />
//     );
//   };
  
//   export default CustomDatePicker;

import React from "react";
import { Input, InputGroup } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { DatePicker } from "antd";
// import "antd/dist/antd.css";
import dayjs, { Dayjs } from "dayjs"; 
// import { RangePickerProps } from "antd/lib/date-picker/generatePicker";

interface CustomDatePickerProps {
  selectedDate: [Dayjs | null, Dayjs | null]; 
  handleDateChange: (date: [Dayjs | null, Dayjs | null]) => void;
  singleDatePicker?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDate,
  handleDateChange,
  singleDatePicker = false,
}) => {

  const handleChange = (dates: [Dayjs | null, Dayjs | null] | Dayjs | null) => {
    if (Array.isArray(dates)) {
      const mappedDates: [Dayjs | null, Dayjs | null] = [
        dates[0] || null,
        dates[1] || null,
      ];
      handleDateChange(mappedDates);
    } else {
      handleDateChange([dates, dates]);
    }
  };

  return (
    // <DatePicker.RangePicker
    //   value={selectedDate} // Use an array of Dayjs objects
    //   onChange={(dates) => {
    //     if (singleDatePicker) {
    //       handleChange(dates ? dates[0] : null);
    //     } else {
    //       handleChange(dates);
    //     }
    //   }}
    //   format="MM/DD/YYYY"
    //   placement="bottomLeft"
    // />
    <>
      {singleDatePicker ? (
        <DatePicker
          value={selectedDate[0]} // Use only the first element for singleDatePicker
          onChange={(date) => handleChange(date)}
          format="MM/DD/YYYY"
          placement="bottomLeft"
        />
      ) : (
        <DatePicker.RangePicker
          value={selectedDate}
          onChange={(dates) => handleChange(dates)}
          format="MM/DD/YYYY"
          placement="bottomLeft"
        />
      )}
    </>
  );
};

export default CustomDatePicker;