import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from 'react-apexcharts';

interface PaymentChartProps {
    paymentData: any[];
}

const PaymentChart: React.FC<PaymentChartProps> = ({ paymentData }) => {
    
    // Check if paymentData is empty or null before mapping over it
    const processedCreditsData = paymentData ? paymentData.map(item => ({
        x: item.month,
        y: item.processed_credits,
    })) : [];

    // Check if paymentData is empty or null before mapping over it
    const unprocessedCreditsData = paymentData ? paymentData.map(item => ({
        x: item.month,
        y: item.unprocessed_credits,
    })) : [];
      
    const chartOptions: ApexOptions = {
        chart: {
            type: "bar",
            toolbar: {
              show: false, 
            },
            foreColor: 'white',
          },
          xaxis: {
            categories: paymentData ? paymentData.map(item => item.month) : [],
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return Math.round(value).toString(); // Format the labels as integers
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Payment Summary",
            align: "center",
            style: {
              fontSize: "25px",
              fontWeight: "bold",
              color: "#fff",
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "center",
            offsetY: -10,
            markers: {
              fillColors: ['#1f77b4', '#ff7f0e'],
            },
          },
      };
    
      const chartSeries = [
        {
            name: 'Processed Credits',
            data: processedCreditsData,
        },
        {
            name: 'Unprocessed Credits',
            data: unprocessedCreditsData,
            color: '#ff7f0e',
        },
      ];
    
      return (
        <div style={{ padding: '5%', background: '#324562', borderRadius: '20px'}}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={'auto'} />
        </div>
      );
  };

export default PaymentChart;