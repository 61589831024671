import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

type ColumnDefinition = {
  key: string;
  header: string;
  flex: number;
};

type CardTableProps<T extends { id: number }> = {
  data: T[];
  columns: ColumnDefinition[];
  renderRow: (item: T) => React.ReactNode;
};

const CardTable = <T extends { id: number }>({
  data,
  columns,
  renderRow,
}: CardTableProps<T>) => {
  // console.log(data);
  return (
    <Box background={"ge"}>
      <Flex
        boxShadow="base"
        borderRadius="md"
        padding="12px"
        backgroundColor="rgb(240 118 21)"
        marginBottom="12px"
      >
        {columns.map((column, index) => (
          <Text
            key={column.key}
            flex={column.flex}
            color="white"
            fontWeight="500"
            marginRight="10px"
            style={{
              borderRight:
                index < columns.length - 1 ? "1px solid white" : "none", // Add separator except for the last column
              paddingRight: "8px", // Add spacing
            }}
          >
            {column.header}
          </Text>
        ))}
      </Flex>
      {data.map((item) => renderRow(item))}
    </Box>
  );
};

export default CardTable;
