import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Flex } from "@chakra-ui/react";
import Chart from "../charts/Chart";
import { getUserInfo, paymentReport } from "../apis/ApiService";
import PaymentChart from "../charts/PaymentChart";
import { errorHandler } from "../services/ErrorHandler";
import { useNavigate } from 'react-router-dom';


const TopPage: React.FC = () => {
  const title = "Home";

  const [paymentData, setPaymentData] = useState<any[]>([]);
  const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await paymentReport();
            setPaymentData(data.data);
            
          } catch (error: any) {
            errorHandler(error, navigate);
          }
        };
      
        fetchData();
      }, []);
      
  return (
    <Flex flexDirection="column" width="100vw" height="100vh">
      <Box>
        <Header title={title}></Header>
      </Box>
      {getUserInfo().role_id === 1 || getUserInfo().role_id === 2 ? ( // Conditionally render PaymentChart
          <Box width="60%" alignSelf="center" margin="5%" flex="auto">
            <PaymentChart paymentData={paymentData}></PaymentChart>
          </Box>
        ) : (
          // Render something else or a message if the user's role doesn't match
          <Box>
            <p>Welcome to JOD!</p>
          </Box>
        )}
    </Flex>
  );
};

export default TopPage;
