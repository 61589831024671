import React from "react";
import Header from "../components/Header";
import CompanyCreditsTable from "../components/tables/CompanyCreditsTable";
import FilterBar from "../components/FilterBar";
import { Card, CardBody } from "@chakra-ui/react";

const CreditPage: React.FC = () => {
  const handleDateChange = (date: Date | null) => {};

  const handleDownload = () => {};

  const title = "Company Credits";
  return (
    <>
      <Header title={title}></Header>
      {/* <FilterBar
        reportDownloadLink="http://127.0.0.1:8000/api/credits/company-credits"
        filterLink="http://127.0.0.1:8000/api/company/active-list"
        filterName="Company Name"
      ></FilterBar> */}
      <Card padding={"1%"}>
        <CardBody>
          <CompanyCreditsTable></CompanyCreditsTable>
        </CardBody>
      </Card>
    </>
  );
};

export default CreditPage;
