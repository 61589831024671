import { useParams } from "react-router-dom";
import CompanyLocationDetails from "./CompanyLocationDetails";

const CompanyLocation = () => {
    const { locationId } = useParams<{ locationId: string }>();
  
    // Check if companyId is undefined or not
    if (locationId === undefined) {
      // Handle the case when companyId is undefined
      return <div>Company ID is missing.</div>;
    }
    const locationIdNumber = parseInt(locationId, 10);
  
    return <CompanyLocationDetails locationId={locationIdNumber} />;
  };
  
  export default CompanyLocation;