import React from "react";
import Header from "../components/Header";
import CompletedJobReferralTable from "../components/tables/CompletedJobReferralTable";

const CompletedJobReferral: React.FC = () => {
    const title = "Completed Job Report"
    return (
        <>
        <Header title={title}></Header>
        <div style={{height: 'calc(100vh - 80px)'}}>
            <CompletedJobReferralTable></CompletedJobReferralTable>
        </div>
        
        </>
    )
}

export default CompletedJobReferral;