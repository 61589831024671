import React from "react";
import { Box, Flex, Spacer, Heading } from "@chakra-ui/react";
import SideMenu from "../components/SideMenu";
import { Image } from '@chakra-ui/react';
import logoImage from "../assets/images/Logo.png"
import { getUserInfo } from "../apis/ApiService";

type HeaderProps = {
    title: string
}

const Header = (props: HeaderProps) => {
    const userName = getUserInfo().first_name + ' ' + getUserInfo().last_name


    return (
        <Flex
            as="header"
            align="center"
            padding={4}
            backgroundColor="white"
        >
            <Box>
                <SideMenu></SideMenu>
            </Box>
            <Box>
                <h1 style={{fontSize: "25px", fontWeight: "bolder"}}>{props.title}</h1>
            </Box>    
            <Spacer></Spacer>
            <Box style={{marginRight: "3%"}}>
                <h2 style={{fontSize: "25px"}}>Welcome {userName || "Guest"}</h2>
            </Box>
            <Box>
                <Heading as="h1" size="lg" color="white">
                    <Image src={logoImage} alt="JOD" onClick={() => (window.location.href = '/')}></Image>
                </Heading>
            </Box>
        </Flex>
    )
}

export default Header;