import React, { useEffect, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Spinner,
  } from '@chakra-ui/react';
import { css } from "@emotion/react";

//Custom Table CSS
const tableStyles = css`
th {
  color: white;
}
`;

interface PaymentReportListProps {
    paymentListData: any[];
    isLoading: boolean;
  }
  
  const PaymentReportTable: React.FC<PaymentReportListProps> = ({
    paymentListData,
    isLoading,
  }) => {
    return (
      <>
        <Box
          sx={{
            overflowX: "auto",
            height: "auto",
            minHeight: `calc(100% - 270px)`,
            backgroundColor: "white",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)", // Adjust the opacity and color as needed
                zIndex: 1,
              }}
            >
              <Spinner size="xl" color="orange.500" />
            </Box>
          ) : (
            <Table variant="simple" css={tableStyles}>
              <Thead sx={{ backgroundColor: "orange" }}>
                <Th>Name</Th>
                <Th>Job ID</Th>
                <Th>Location</Th>
                <Th>Clock In</Th>
                <Th>Clock Out</Th>
                <Th>Total Hours</Th>
                <Th>JOD Credit</Th>
                <Th>Processed Date</Th>
              </Thead>
              <Tbody sx={{ fontWeight: "300" }}>
              {(paymentListData.map((payment) => (
                    <Tr key={payment.payment_id}>
                    <Td>{payment.full_name}</Td>
                    <Td>{payment.job_id}</Td>
                    <Td>{payment.location_name}</Td>
                    <Td>{payment.clock_in}</Td>
                    <Td>{payment.clock_out}</Td>
                    <Td>{payment.total_hours}</Td>
                    <Td>{payment.total_credits}</Td>
                    <Td>{payment.processed_date}</Td>
                    </Tr>
                )))
                }
              </Tbody>
            </Table>
          )}
        </Box>
      </>
    );
  };
  
  export default PaymentReportTable;
  