import React, { useEffect } from "react";
import Header from "../components/Header";
import CompanyCreditsTable from "../components/tables/CompanyCreditsTable";
import FilterBar from "../components/FilterBar";
import { Box, Card, CardBody, Flex } from "@chakra-ui/react";
import Chart from "../charts/Chart";
import { useState } from "react";
import { completedJobAnalytics } from "../apis/ApiService";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../services/ErrorHandler";

interface DataItem {
  company_id: string;
  location_id: string;
  total_count: number;
  date: string;
}

const JobsPage: React.FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);

  // const handleDateChange = (date: Date | null) => {};

  const handleDownload = () => {};

  const [data, setData] = useState<DataItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(selectedDateRange[0], selectedDateRange[1], null, null);
  }, [selectedDateRange[0], selectedDateRange[1], null, null]);

  const fetchData = async (
    startDate: dayjs.Dayjs | null,
    endDate: dayjs.Dayjs | null,
    company_id: string | null,
    location_id: string | null
  ) => {
    try {
      let analyticsData = await completedJobAnalytics(
        startDate,
        endDate,
        company_id,
        location_id
      );
      setData(analyticsData);
    } catch (error: any) {
      errorHandler(error, navigate);
    }
  };

  const handleSearch = ( searchParams: {
    keyword?: string;
    startDate?: dayjs.Dayjs | null;
    endDate?: dayjs.Dayjs | null;
  }) => {
    setSelectedDateRange([searchParams.startDate || null, searchParams.endDate || null]);
  };

  const title = "Completed Jobs";
  return (
    <>
    <Flex flexDirection="column" width="100vw" height="100%">
      <Box position="fixed" top={0} width="100%" zIndex={10}>
        <Header title={title}></Header>
      </Box>
      {/* <FilterBar
        reportDownloadLink="http://127.0.0.1:8000/api/credits/company-credits"
        filterLink="http://127.0.0.1:8000/api/company/active-list"
        filterName="Company Name"
      ></FilterBar> */}
      <Box height="100%" padding="5%">
        <Box
            mt="60px" 
            width="80%" 
            mx="auto" 
            p="20px"
            background="white"
            borderRadius="10px"
            boxShadow="md"
          >
            <Box height="100%" padding='1%' background='white' borderTopRadius='10px'>
              <HeaderSearchBar
                onSearch={handleSearch}
                showDateRangePicker
              />
        </Box>
      <Box sx={{ width: "100%", height: "auto", padding: "1%" }}>
        <Card>
          <CardBody>
          {data.length > 0 ? (
            <Chart
              type="bar"
              categories={data.map(({ date }) => date)}
              values={data.map(({ total_count }) => total_count)}
            ></Chart>
            ) : (
              <p>No data available for the selected date range.</p>
            )}
          </CardBody>
        </Card>
      </Box>
      {/* <Box sx={{ width: "100%", height: "auto", padding: "1%" }}>
        <Card>
          <CardBody>
            <NewSignUpsTable></NewSignUpsTable>
          </CardBody>
        </Card>
      </Box> */}
      </Box>
      </Box>
      </Flex>
    </>
  );
};

export default JobsPage;
