import { Box, Flex, Image, Progress } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { QuestionIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { locationShow } from "../apis/ApiService";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import CardTable from "../components/molecules/CardTable";
import Pagination from "../components/atoms/Pagination";
import { locationCreditHistory } from "../apis/ApiService";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../services/ErrorHandler";

interface Location {
    id: number;
    name: string;
    location_logo_filename: string;
    address: string;
    job_credit_deduction: number;
    consumed_credits: number;
    available_credits: number;
    min_jod_credit_limit: number;
  }

  interface LocationCreditHistoryList {
    id: number;
    name: string;
    entity_name?: string;
    applicant_name?: string;
    transaction_date: string;
    consumed_credits: number;
    assigned_credits: number;
    available_credits: number;

}

const CompanyLocationDetails = ({ locationId }: { locationId: number }) => {
    const title = 'Location Details';
    const [location, setLocation] = useState<Location | null>(null);

    const [data, setData] = useState<LocationCreditHistoryList[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [selectedDateRange, setSelectedDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([null, null]);

    const [imageError, setImageError] = useState(true);

    useEffect(() => {
        const fetchLocationData = async () => {
          try {
            const locationData = await locationShow(locationId);
            setLocation(locationData.data);
          } catch (error: any) {
            errorHandler(error, navigate);
          }
        };
    
        fetchLocationData();
      }, [locationId]);

      useEffect(() => {
        const fetchLocationCreditHistoryData = async (page: number, location_id: number) => {
            try {
                setLoading(true); // Set loading to true while fetching data
                const locationCreditHistoryData = await locationCreditHistory(page, location_id);
                setData(locationCreditHistoryData.data.data);
                setTotalPages(locationCreditHistoryData.data.last_page);
            } catch (error: any) {
              errorHandler(error, navigate);
            } finally {
              setLoading(false);
            }
          };

          fetchLocationCreditHistoryData(currentPage, locationId);
      }, [currentPage, locationId]);

      const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
      };

      const handleSearch = (searchParams: {
        keyword?: string;
        startDate?: dayjs.Dayjs | null; 
        endDate?: dayjs.Dayjs | null;
      }) => {
        setSelectedDateRange([searchParams.startDate || null, searchParams.endDate || null]);
      };

    const formatCredits = (credits: number) => {
        return credits.toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      };

      const columns = [
        { key: 'name', header: 'Transaction Details', flex: 2 },
        { key: 'transaction_type', header: 'Transaction Type', flex: 1 },
        { key: 'transaction_date', header: 'Transaction Date', flex: 1 },
        { key: 'credits', header: 'Transaction Credits', flex: 1 },
        { key: 'available_credits', header: 'Available Credits', flex: 1 },
      ];

      const renderRow = (item: LocationCreditHistoryList) => (
        <Box
            style={{
            backgroundColor: 'white',
            color: 'black',
            fontWeight: '300',
            transition: 'background-color 0.3s ease-in-out',
            borderRadius: 'md', 
            }}
            borderRadius="md"
            marginBottom="10px"
            padding="18px"
        >
        <Flex align="center">
            <Text flex={2}>{item.entity_name ? item.entity_name: item.applicant_name}</Text>
            <Text flex={1}>
                {item.assigned_credits !== null ? 'Assigned' : item.consumed_credits !== null ? 'Consumed' : ''}
            </Text>
            <Text flex={1}>{item.transaction_date}</Text>
            <Text flex={1}>
            {item.assigned_credits !== null
                ? formatCredits(item.assigned_credits)
                : item.consumed_credits !== null
                ? formatCredits(item.consumed_credits)
                : ''}
            </Text>
            <Text flex={1} color={item.available_credits < 0 ? 'red' : 'inherit'}>
                {formatCredits(item.available_credits)}
            </Text>
        </Flex>
        </Box>
      );

    return (
        <Flex flexDirection="column" width="100vw" height="100%">
        <Box position="fixed" top={0} width="100%" zIndex={10}>
          <Header title={title}></Header>
        </Box>
        <Box height="100%" padding="5%">
            <Box
                mt="60px" 
                width="80%" 
                mx="auto" 
                p="20px"
                background="white"
                borderRadius="10px"
                boxShadow="md"
                >
                <Flex flexDirection="column">
                    {/* Company Logo and Details */}
                    {location && (
                    <>
                        <Box alignItems="center" textAlign="center" mb={4}>
                            {imageError ? (
                                <QuestionIcon marginRight='5px' height='40px' width='40px' color='lightgray' />
                            ) : (
                                <Image
                                src={location?.location_logo_filename}
                                alt={location.name}
                                w="100%"
                                h="100%"
                                onError={() => setImageError(true)}
                                />
                            )}
                            <Text fontWeight="bold" fontSize={30}>{location.name}</Text>
                            <Text fontSize={24}>{location.address}</Text>
                        </Box>
                        <Box alignItems="center" textAlign="center" mb={4}>
                            <Text fontWeight="700">{"Consumed Credits: " + location.consumed_credits}</Text>
                            <Text fontWeight="700">{"Available Credits: " + location.available_credits}</Text>
                        </Box>
                    </>
                )}
                </Flex>
            </Box>
            <Box height="100%" padding="5%" paddingTop='5%'>
                <Box height="100%" padding='1%' background='white' borderTopRadius='10px'>
                    <HeaderSearchBar
                    onSearch={handleSearch}
                    placeholder="Enter Company Name"
                    showKeywordField
                    showDownloadButton
                />
                </Box>
            
                {loading ? (
                <Progress size='xs' isIndeterminate /> 
                ) : (
                    <>
                    {data.length === 0 ? ( 
                    <Box
                        height="100%"
                        padding='1%'
                        background='white'
                        borderRadius='10px'
                        marginTop='1%'
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center"
                    >
                        <Text>No data available.</Text>
                    </Box>
                    ) : (
                    <>
                        <CardTable data={data} columns={columns} renderRow={renderRow} />
                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    </>
                    )}
                </>
                )}
            </Box>
        </Box>
      </Flex>
    );
}

export default CompanyLocationDetails;