import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import { companyList } from "../apis/ApiService";
import CardTable from "../components/molecules/CardTable";
import Pagination from "../components/atoms/Pagination";
import { Progress } from '@chakra-ui/react';
import { QuestionIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { errorHandler } from "../services/ErrorHandler";

interface Company {
    id: number;
    name: string;
    url_company_logo: string;
    address: string;
    consumed_credits: number;
    available_credits: number;
    min_jod_credit_limit: number;
  }

export const CompanyList = () => {
    const title = "Companies";
    const [data, setData] = useState<Company[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [keyword, setKeyword] = useState<string | null>(null);
    const [hoveredCompanyId, setHoveredCompanyId] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState(false);
    const navigate = useNavigate();

    const columns = [
        { key: 'name', header: 'Company Name', flex: 2 },
        { key: 'address', header: 'Address', flex: 3 },
        { key: 'consumed_credits', header: 'Consumed Credits', flex: 1 },
        { key: 'available_credits', header: 'Available Credits', flex: 1 },
        { key: 'min_jod_credit_limit', header: 'Min. Credit Limit', flex: 1 },
      ];

      const fetchData = async (page: number, searchKeyword: string | null) => {
        try {
          setLoading(true); 
          const companyListData = await companyList(page, searchKeyword);
          setData(companyListData.data);
          setTotalPages(companyListData.last_page);
        } catch (error: any) {
          errorHandler(error, navigate);
        } finally {
          setLoading(false); 
        }
      };

      const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
      };
    
      useEffect(() => {
        fetchData(currentPage, keyword);
      }, [currentPage, keyword]);

      const handleRowClick = (companyId: number) => {
        navigate(`/company/${companyId}`);
      };

      const handleRowHover = (companyId: number) => {
        setHoveredCompanyId(companyId);
      };
      
      const handleRowHoverLeave = () => {
        setHoveredCompanyId(null);
      };

      const handleSearch = (searchParams: {
        keyword?: string;
        startDate?: dayjs.Dayjs | null;
        endDate?: dayjs.Dayjs | null;
      }) => {
        setKeyword(searchParams.keyword || null);
        setCurrentPage(1);
      };      

      const renderRow = (item: Company) => (
        <Box
            onClick={() => handleRowClick(item.id)}
            onMouseEnter={() => handleRowHover(item.id)}
            onMouseLeave={handleRowHoverLeave}
            style={{
            cursor: 'pointer',
            backgroundColor: hoveredCompanyId === item.id ? 'rgb(255, 186, 134)' : 'white',
            color: hoveredCompanyId === item.id ? 'white' : 'black',
            fontWeight: hoveredCompanyId === item.id ? '600' : '300',
            transition: 'background-color 0.3s ease-in-out',
            borderRadius: 'md', // Optional: Add border radius for a card-like appearance
            boxShadow: hoveredCompanyId === item.id ? 'md' : 'none', // Optional: Add a box shadow on hover
            }}
            borderRadius="md"
            marginBottom="10px"
            padding="18px"
        >
        <Flex align="center">
            <Text flex={2}>
                {imageError ? (
                    <QuestionIcon marginRight='5px' height='40px' width='40px' color='lightgray' />
                ) : (
                    <Image
                    src={item.url_company_logo}
                    alt={item.name}
                    w="100%"
                    h="100%"
                    onError={() => setImageError(true)}
                    />
                )}
                {item.name}
            </Text>
            <Text flex={3}>{item.address}</Text>
            <Text flex={1}>{item.consumed_credits.toLocaleString()}</Text>
            <Text flex={1} color={item.available_credits < 0 ? 'red' : 'inherit'}>
                {item.available_credits.toLocaleString()}
            </Text>
            <Text flex={1}>{item.min_jod_credit_limit.toLocaleString()}</Text>
        </Flex>
        </Box>
      );      

    return (
        <Flex flexDirection="column" width="100vw" height="100%">
        <Box position="fixed" top={0} width="100%" zIndex={10}>
          <Header title={title}></Header>
        </Box>
        <Box height="100%" padding="5%">
            <Box height="100%" padding='1%' background='white' borderTopRadius='10px'>
                <HeaderSearchBar
                onSearch={handleSearch}
                placeholder="Enter Company Name"
                showKeywordField
                showDownloadButton
            />
            </Box>
        
            {loading ? (
            <Progress size='xs' isIndeterminate /> // Show the progress while loading
            ) : (
                <>
                {data.length === 0 ? ( // Check if there is no fetched data
                <Box
                    height="100%"
                    padding='1%'
                    background='white'
                    borderRadius='10px'
                    marginTop='1%'
                    display="flex" // Use flexbox
                    justifyContent="center" // Center horizontally
                    alignItems="center" // Center vertically
                >
                    <Text>No data available.</Text>
                </Box>
                ) : (
                  <>
                    <CardTable data={data} columns={columns} renderRow={renderRow} />
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                  </>
                )}
              </>
            )}
        </Box>
      </Flex>
    );
}