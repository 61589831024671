import React from "react";
import { VStack, Card, InputGroup, Center, InputRightElement, Button, SlideFade } from "@chakra-ui/react";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
  } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { Icon } from "@chakra-ui/react";
import {LogoIcon, TextLogoIcon} from '../assets/icons'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { loginApi } from "../apis/ApiService";
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux/authSlice';
import { errorHandler } from "../services/ErrorHandler";

interface LoginPageProps {
    onLoginSuccess: () => void;
  }
  
  const LoginPage = ({ onLoginSuccess }: LoginPageProps) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = React.useState(false)
    const navigate = useNavigate();
    const [submitted, setSubmitted] = React.useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        
        try {

            const userData = await loginApi(email, password);

            // Dispatch the loginSuccess action when login is successful
            dispatch(loginSuccess(userData));
        
            // Reset the form after successful login
            setEmail('');
            setPassword('');
            setSubmitted(true);
            setError('');

            onLoginSuccess(); 
            navigate('/home');
        
        } catch (error: any) {
          // Handle any errors that occur during the login process
          errorHandler(error, navigate);
        }
      };

    const handleClick = () => setShow(!show)

    const validateEmail = (email: string) => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Center h="100vh">
            <SlideFade in={true} offsetY="-20px">
                <Card shadow="2xl" p="30px" w="90vw" maxW="450px" padding="5%">
                    <VStack spacing="25px" mb="50px" mt="5%">
                        <Icon as={LogoIcon} w="40%" height="auto"></Icon>
                        <Icon as={TextLogoIcon} w="100%" height="auto"></Icon>
                    </VStack>
                    <form onSubmit={handleSubmit}>
                    <FormControl isInvalid={!!((submitted && !email) || (email && !validateEmail(email)))}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            placeholder="Enter your email here"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {(email === "" || (email && !validateEmail(email))) && (
                            <FormErrorMessage>
                            {email === "" ? "Please enter your email." : "Please enter a valid email address."}
                            </FormErrorMessage>
                        )}
                        </FormControl>
                        <FormControl isInvalid={!password && error !== ''}>
                        <FormLabel sx={{mt: "5%"}}>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    pr='4.5rem'
                                    type={show ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            {(!password) && (
                            <FormErrorMessage>Please enter your password</FormErrorMessage>
                        )}
                        </FormControl>
                        {/* {error && (
                            <Text color="red.500" mb="5%">
                                {error}
                            </Text>
                        )} */}
                        <Button size='lg' width="100%" type="submit" mt={error ? "5%" : "10%" } disabled={!email || !password || !!error}>
                            Login
                        </Button>
                    </form>
                </Card>
            </SlideFade>
        </Center>
    )

}

export default LoginPage;