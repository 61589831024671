import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import CardTable from "../components/molecules/CardTable";
import Pagination from "../components/atoms/Pagination";
import HeaderSearchBar from "../components/molecules/HeaderSearchBar";
import { Progress } from "@chakra-ui/react";
import dayjs from "dayjs";
import { companyMonthlyCredits, downloadCompanySOA } from "../apis/ApiService";
import { statementOfAccount } from "../apis/ApiService";
import { Stack } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { errorHandler } from "../services/ErrorHandler";
import { useNavigate } from "react-router";

interface DataItem {
  id: number;
  job_id: number;
  slot_id: number;
  admin_insurance_amount: number;
  insurance_amount: number;
  account_holder_name: string;
  account_number: string;
  bank_code: string;
  adjusted_jod_credit: number;
  slot_user_status: number;
  payment_status: number;
  processed_date: string;
  bank_paid: number;
  location_id: number;
  location_name: string;
  user_id: number;
  manager_full_name: string;
  full_name: string;
  unique_id: string;
  is_deleted: number;
  avatar_url: string;
  job_title: string;
  hourly_rate: number;
  clock_in: string;
  clock_out: string;
  break_time: number;
  total_hours: number;
  total_credits: number;
  net_pay: number;
  admin_total_hours: number;
  admin_total_credits: number;
  credit_before_transaction: number;
  credit_after_transaction: number;
  admin_net_pay: number;
  has_adjustment: number;
  admin_clock_in_date: string;
  admin_clock_out_date: string;
  admin_clock_in_time: string;
  admin_clock_out_time: string;
  admin_hourly_rate: number;
  admin_break_time: number;
  admin_comment: string;
  admin_jod_allowance: number;
}

interface Credits {
  id: number;
  assigned_credits: number;
  comment: string;
  date: string;
  company_id: number;
}

export const SOAPage = () => {
  const navigate = useNavigate();
  const title = "Statement of Accounts";
  const [data, setData] = useState<DataItem[]>([]);
  const [creditsData, setCreditsData] = useState<Credits[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([null, null]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null
  );
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [creditsCurrentPage, setCreditsCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [creditsTotalPages, setCreditsTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);
  const [totalCreditsAdded, setTotalCreditsAdded] = useState(0);

  const creditColumns = [
    { key: "comment", header: "Comment", flex: 5 },
    { key: "assigned_credits", header: "Assigned Credit Amount", flex: 2 },
    { key: "created_at", header: "Date Assigned", flex: 3 },
  ];

  const columns = [
    { key: "applicant_name", header: "Full Name", flex: 5 },
    { key: "nric", header: "NRIC/FIN", flex: 4 },
    { key: "location_name", header: "Outlet Name", flex: 5 },
    { key: "manager_name", header: "Hiring Manager Name", flex: 4 },
    { key: "job_title", header: "Job Type", flex: 4 },
    { key: "clock_in_date", header: "Clock In Date", flex: 4 },
    { key: "clock_out_date", header: "Clock Out Date", flex: 4 },
    { key: "clock_in_time", header: "Clock In Time", flex: 4 },
    { key: "clock_out_time", header: "Clock Out Time", flex: 4 },
    { key: "break_time", header: "Meal Break Time Hours", flex: 4 },
    { key: "total_hours_worked", header: "Total Hours Worked", flex: 4 },
    { key: "hourly_rate", header: "Wages Per Hour ($)", flex: 4 },
    {
      key: "credits_before_transaction",
      header: "Credits Before Transaction",
      flex: 4,
    },
    {
      key: "credits_after_transaction",
      header: "Credits After Transaction",
      flex: 4,
    },
    { key: "jod_credits", header: "Total Credits Used ($)", flex: 4 },
    { key: "comment", header: "Comment", flex: 6 },
  ];

  const getCreditsData = async (
    page: number,
    companyId: number | null,
    locationId: number | null,
    selectedDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    try {
      setCreditsLoading(true);
      const companyCreditsData = await companyMonthlyCredits(
        page,
        selectedDateRange[0],
        selectedDateRange[1],
        companyId,
        locationId
      );
      setTotalCreditsAdded(companyCreditsData.total_added);
      setCreditsData(companyCreditsData.data);
      setCreditsTotalPages(companyCreditsData.last_page);
    } catch (error: any) {
      errorHandler(error, navigate);
    } finally {
      setCreditsLoading(false); // Set loading back to false after fetching is done
    }
  };

  const fetchData = async (
    page: number,
    companyId: number | null,
    locationId: number | null,
    selectedDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    try {
      setLoading(true);
      const SOAListData = await statementOfAccount(
        page,
        selectedDateRange[0],
        selectedDateRange[1],
        "",
        companyId,
        locationId
      );
      setTotal(SOAListData.total);
      setTotalCreditsUsed(SOAListData.total_credits_used);
      setData(SOAListData.data);
      setTotalPages(SOAListData.last_page);
    } catch (error: any) {
      errorHandler(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (isDownloading) {
      return;
    }

    try {
      setIsDownloading(true);
      let downloadData = await downloadCompanySOA(
        selectedCompanyId,
        selectedName,
        selectedLocationId,
        selectedDateRange[1]
      );

      if (downloadData) {
        const currentDate = new dayjs.Dayjs();
        const dateParam = currentDate.format("YYYY-MM-DD");
        const fileName = `SOA-${dateParam}.xlsx`;

        const blob = new Blob([downloadData], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, fileName);
      }
    } catch (error: any) {
      errorHandler(error, navigate);
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  const handleCreditsPageChange = (newPage: number) => {
    setCreditsLoading(true);
    setCreditsCurrentPage(newPage);
  };

  //Set totals to zero when filters are changed.
  useEffect(() => {
    setTotal(0);
    setTotalCreditsUsed(0);
    setTotalCreditsAdded(0);
  }, [selectedCompanyId, selectedLocationId, selectedDateRange]);

  useEffect(() => {
    if (selectedCompanyId != null) {
      fetchData(
        currentPage,
        selectedCompanyId,
        selectedLocationId,
        selectedDateRange
      );
    }
  }, [currentPage, selectedCompanyId, selectedLocationId, selectedDateRange]);

  useEffect(() => {
    if (selectedCompanyId != null) {
      getCreditsData(
        creditsCurrentPage,
        selectedCompanyId,
        selectedLocationId,
        selectedDateRange
      );
    }
  }, [
    creditsCurrentPage,
    selectedCompanyId,
    selectedLocationId,
    selectedDateRange,
  ]);

  const handleSearch = (searchParams: {
    keyword?: string;
    startDate?: dayjs.Dayjs | null;
    endDate?: dayjs.Dayjs | null;
    companyId?: number | null;
    locationId?: number | null;
    reportName?: string | null;
  }) => {
    setSelectedDateRange([
      searchParams.startDate || null,
      searchParams.endDate || null,
    ]);
    setSelectedLocationId(searchParams.locationId || null);
    setSelectedCompanyId(searchParams.companyId || null);
    setSelectedName(searchParams.reportName || null);
    setCurrentPage(1);
  };

  const CreditsRenderRow = (item: Credits) => (
    <Box
      style={{
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "md", // Optional: Add border radius for a card-like appearance
      }}
      borderRadius="md"
      marginBottom="10px"
      padding="9px"
    >
      <Flex align="center">
        <Text key={"comment"} flex={5} overflowWrap={"break-word"}>
          {item.comment}
        </Text>
        <Text key={"assigned_credits"} flex={2}>
          {item.assigned_credits.toFixed(2)}
        </Text>
        <Text key={"date"} flex={3}>
          {item.date}
        </Text>
      </Flex>
    </Box>
  );

  const renderRow = (item: DataItem) => (
    <Box
      style={{
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "md", // Optional: Add border radius for a card-like appearance
      }}
      borderRadius="md"
      marginBottom="10px"
    >
      <HStack
        alignItems="center"
        boxShadow="base"
        borderRadius="md"
        padding="12px"
        marginBottom="12px"
      >
        <Text key={"full_name"} flex={5} overflowWrap={"break-word"}>
          {item.full_name}
        </Text>
        <Text key={"nric"} flex={4}>
          {item.unique_id}
        </Text>
        <Text key={"location_name"} flex={5} overflowWrap={"break-word"}>
          {item.location_name}
        </Text>
        <Text key={"manager_full_name"} flex={4} overflowWrap={"break-word"}>
          {item.manager_full_name}
        </Text>
        <Text key={"job_title"} flex={4}>
          {item.job_title}
        </Text>
        <Text key={"clock_in_date"} flex={4}>
          {item.admin_clock_in_date}
        </Text>
        <Text key={"clock_out_date"} flex={4}>
          {item.admin_clock_out_date}
        </Text>
        <Text key={"clock_in_time"} flex={4}>
          {item.admin_clock_in_time}
        </Text>
        <Text key={"clock_out_time"} flex={4}>
          {item.admin_clock_out_time}
        </Text>
        <Text key={"break_time"} flex={4}>
          {item.admin_break_time}
        </Text>
        <Text key={"admin_total_hour"} flex={4}>
          {item.admin_total_hours}
        </Text>
        <Text key={"hourly_rate"} flex={4}>
          {item.admin_hourly_rate}
        </Text>
        <Text key={"credit_before_transaction"} flex={4}>
          {item.credit_before_transaction}
        </Text>
        <Text key={"credit_after_transaction"} flex={4}>
          {item.credit_after_transaction}
        </Text>
        <Text key={"admin_jod_credit"} flex={4}>
          {item.total_credits}
        </Text>
        <Text key={"comment"} flex={6} overflowWrap={"break-word"}>
          {item.admin_comment}
        </Text>
      </HStack>
    </Box>
  );

  return (
    <Flex flexDirection="column" width="100vw" height="100%">
      <Box position="fixed" top={0} width="100%" zIndex={10}>
        <Header title={title}></Header>
      </Box>
      <Box height="100%" padding="5%">
        <Box
          height="100%"
          padding="1%"
          background="white"
          borderTopRadius="10px"
        >
          <HeaderSearchBar
            onSearch={handleSearch}
            handleDownload={handleDownload}
            placeholder="Enter Company Name"
            showKeywordField={false}
            showDownloadButton
            showDateRangePicker
            isDownloading={isDownloading}
            showCompanyDropDown={true}
            showLocationDropDown={true}
          />
        </Box>
        <Box
          background={"white"}
          padding={"1%"}
          marginY={"1%"}
          borderRadius="10px"
          display="flex" // Use flexbox
          alignItems="center" // Center vertically
          justifyContent="center" // Center horizontally
        >
          <HStack spacing="36px">
            <Stack
              flex={"1"}
              direction="column"
              flexWrap={"wrap"}
              alignItems="center"
            >
              <Text
                as="b"
                color={"tomato"}
                alignContent={"center"}
                fontSize={"3xl"}
              >
                {total}
              </Text>
              <Text
                alignContent={"center"}
                textAlign={"center"}
                fontSize={"lg"}
              >
                Total Number of Completed Jobs
              </Text>
            </Stack>
            <Stack
              flex={"1"}
              direction="column"
              flexWrap={"wrap"}
              alignItems="center"
            >
              <Text
                as="b"
                color={"tomato"}
                alignContent={"center"}
                fontSize={"3xl"}
              >
                {totalCreditsUsed.toFixed(2)}
              </Text>
              <Text
                alignContent={"center"}
                textAlign={"center"}
                fontSize={"lg"}
              >
                Total Credits Used
              </Text>
            </Stack>
            <Stack
              flex={"1"}
              direction="column"
              flexWrap={"wrap"}
              alignItems="center"
            >
              <Text
                as="b"
                color={"tomato"}
                alignContent={"center"}
                fontSize={"3xl"}
              >
                {totalCreditsAdded.toFixed(2)}
              </Text>
              <Text
                alignContent={"center"}
                textAlign={"center"}
                fontSize={"xlg"}
              >
                Total Credits Added
              </Text>
            </Stack>
          </HStack>
        </Box>

        {loading || creditsLoading ? (
          <Progress size="xs" isIndeterminate /> // Show the progress while loading
        ) : (
          <>
            <Box>
              <Text fontSize={"2xl"} fontStyle={"bold"} marginY={"1%"}>
                Credits Used
              </Text>
            </Box>
            {data.length === 0 ? ( // Check if there is no fetched data
              <Box
                height="100%"
                padding="1%"
                background="white"
                borderRadius="10px"
                marginTop="1%"
                display="flex" // Use flexbox
                justifyContent="center" // Center horizontally
                alignItems="center" // Center vertically
              >
                <Text>No data available.</Text>
              </Box>
            ) : (
              <>
                <CardTable
                  data={data}
                  columns={columns}
                  renderRow={renderRow}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            )}

            <Box>
              <Text fontSize={"2xl"} fontStyle={"bold"} marginY={"1%"}>
                Credits Added
              </Text>
            </Box>
            {creditsData.length === 0 ? ( // Check if there is no fetched data
              <Box
                height="100%"
                padding="1%"
                background="white"
                borderRadius="10px"
                marginTop="1%"
                display="flex" // Use flexbox
                justifyContent="center" // Center horizontally
                alignItems="center" // Center vertically
              >
                <Text>No data available.</Text>
              </Box>
            ) : (
              <>
                <CardTable
                  data={creditsData}
                  columns={creditColumns}
                  renderRow={CreditsRenderRow}
                />
                <Pagination
                  currentPage={creditsCurrentPage}
                  totalPages={creditsTotalPages}
                  onPageChange={handleCreditsPageChange}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

// export default SOAPage;
